import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Modal from 'react-responsive-modal';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import CSVFileValidator from 'csv-file-validator';
import Deliverconsignment from './deliverconsignment';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Consignmentforceclose from '../manage/consignmentforceclose.js';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import CourierDelayedRemarks from './remarksButton';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import CourierActions from '../layouts/courierActions';
import CourierConsignmentActions from '../manage/CourierConsignmentActionsComponent';
import 'react-vertical-timeline-component/style.min.css';
import TruckSidebarComponent from './truckSidebarComponent';
import * as Datetime from 'react-datetime';
import CourierCounter from './couriercounter';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS,getHyphenDDMMYYYY,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenYYYYMMDD,getHyphenDDMMYYYYHHMMSS } from '../common/utils'; 
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
//import datetimepicker from 'jquery-datetimepicker';
import Select from 'react-select';
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');
var DrawMap = require('../common/drawmap');
 
var moment = require('moment');
//$.datetimepicker.setLocale('en');
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;

export default class ManageCourierConsignments extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "",
			allPlantsCoords:{},
			coordArr:[],
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
			show: false,
			markDeliverDefault:"",
			remarksInput:"",
			deliveredOtherReason:"",
            basicTitle:'',
			remarks_row_data:"",
			basicType:"default",
			showPriority: false,
            basicPriorityTitle:'',
			basicPriorityType:"default",
			selectedRow:"",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
      	    valuecolumns:[],
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
      	      
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true
      	    },
      	    rowData: [],
      	      
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			frameworkComponents: {
				courierActions:CourierActions,
				courierConsignmentActions:CourierConsignmentActions,
				consignmentforceclose : Consignmentforceclose,
				MarkDeliver:MarkDeliver,
				CourierDelayedRemarks:CourierDelayedRemarks,
				customLoadingCellRenderer: CustomLoadingCellRenderer,
				deliverconsignment:Deliverconsignment
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
			
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1"
			},
			intransit : [],
			outdeliver : [],
			deliver : [],
			datana : [],
			intransitcount : [],
			outdelivercount : [],
			delivercount : [],
			datanacount : [],
			prioritydata : [],
			prioritydatacount : 0,
			totalcount:0,
			from_date:'',
			to_date:"",
			consigner:[{"value":"all", "label":"All"}],
			consigners:[],
			originaldata:[],
			counttype:'all',
			forceCloseRowNode:"",	
			forceclosedata:"",
			sliderForceCloseTranslate:"",
			sliderRemarksTranslate:"",
			overly : "show-n",
			markDeliverRowNode : "",
			markDeliverData:"",
			sliderMarkDeliverTranslate:"",
			bulkPrioritySlide : "",
			bulkForceCloseSlide : "",
			bulkMarkDeliverSlide : "",
			file:"",
			inputMarkDeliverFile : "",
			uploadDivWidth:"",
			csvcontent:"",
			mdCsvContent : "",
			deliveryStatus : "",
			deliverStatusValue : "",
			distance_from_dealer_location : "",
			deliveredDeptCode : "",
			deliveredTruckNo : "",
			delivermodal: false,
			hideTransporterBtns : "show-m",
			confirmDelivery:"",
			displayOtherField : "show-n",
			selectedDeliveryReason : "",
			usergridstate:[],
			screenurl:"",
			screentitle:"",
			critical_data : [],
			critical_counter_data : [],
			cancelled_data: [],
			pod_received_data: [],
			trip_closed_by_pod: [], 
			delDate:"none",
			sliderCreateConsignment:"",
			selectedService:{ "value": "Select Service", "label": "Select Service" },
			grno_consignment_code:[],
			mgpa_number:[],
			sliderRso:"",
			plant_courier_codes:[],
			selectedPlantCode:{ "value": "Select Plant Code", "label": "Select Plant Code" },
			selectedDealerCode:{ "value": "Select Dealer Code", "label": "Select Dealer Code" },
			selectedDealerCity:{ "value": "Select Dealer City", "label": "Select Dealer City" },
			dealercouriercode:[],
			dealercouriercity:[],
			plantcouriercodes:[],
			uploadfilebulk:'',
			transit_time:[],
			courier_vehicle_mode:[],
			on_time_data:[],
			delayed_data:[],
			on_time_data_count:[],
			delayed_data_count:[],
			force_close_count:[],
			forceclosecount:[],
			showRemarksButton : false,
			sliderBulkRemarks:"",
			sliderBlukValidateRemarks:"",
			csvcontentforremarks:"",
			csvcontentforvalidateremarks:"",
			sliderBulkMarkDeliverd:"",
			csvcontentformarkdelivered:"",
			courierConsigneeCode:"",
		}
		this.onClickForceClose = this.onClickForceClose.bind(this);
		this.onClickMarkDelivered = this.onClickMarkDelivered.bind(this);
		this.onClickGetRemarks = this.onClickGetRemarks.bind(this);
		this.onLoadShowCouriers = this.onLoadShowCouriers.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.cipher = this.cipher.bind(this);
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
		try{
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		catch(e){
	
		}
		
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount(){
		loadDateTimeScript();
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		// $("#from_date,#to_date").on("click",function(){
		// 	$(".xdsoft_timepicker").css("display","none");
		// });
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

		if (localStorage.getItem('user_type') == "COURIER_CONSIGNEE") {
			var courierConsigneeCode = localStorage.getItem("consignee_code");
			this.setState({
				courierConsigneeCode: courierConsigneeCode
			});
		}
		
		
		var params = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname
		}

		redirectURL.post("/consignments/usergridstates", params)
		.then( async (resp) => {
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})
		console.log("Props ", this.props.match)
		// if(this.props.match.path == "/activecourierconsignments")
		// {
		// 	var fdate = moment.parseZone().subtract(30, 'days').format('DD-MM-YYYY');
		// }
		// else
		// {
		// 	var fdate = moment.parseZone().subtract(10, 'days').format('DD-MM-YYYY');

		// }
		var fdate = moment.parseZone().subtract(30, 'days').format('DD-MM-YYYY');
		var tdate = moment.parseZone().format('DD-MM-YYYY');
		var fdt = fdate.split("-");
		var fromdate = fdt[2]+"-"+fdt[1]+"-"+fdt[0];
		var tdt = tdate.split("-");
		var todate = tdt[2]+"-"+tdt[1]+"-"+tdt[0];
		document.getElementById("from_date").value=fdate;
		document.getElementById("to_date").value=tdate;
			
		var urlpath;
		var dpt;
		var counttype;
		var serviceProviders = [];
		if(localStorage.getItem("user_type") == "TRANSPORTER")
		{
			this.setState({
				hideTransporterBtns :"show-n"
			});
			serviceProviders = JSON.parse(localStorage.getItem('transportercode'))
		}
		
		params = {}

		if (courierConsigneeCode != "" && courierConsigneeCode != null && courierConsigneeCode != undefined) {
			params.courierConsigneeCode = courierConsigneeCode
		}
		
		redirectURL.post("/dashboard/getprtconsigneescity", params)
		.then((responsecitycodes) => {
			console.log(responsecitycodes,"responsecitycodes")
			var dealer_courier_code = responsecitycodes.data.records
			var dealercouriercity = []
			dealer_courier_code.map((e) => {
				if(e != "" && e != undefined)
				{
					dealercouriercity.push({ value: e, label: e })
				}
			})
			console.log(dealercouriercity,"dealercouriercity")
			this.setState({
				dealercouriercity:dealercouriercity
			});
		})

		params = {}

		if (courierConsigneeCode != "" && courierConsigneeCode != null && courierConsigneeCode != undefined) {
			params.courierConsigneeCode = courierConsigneeCode
		}
		
		redirectURL.post("/dashboard/prtconsignees", params)
		.then((responsecodes) => {
			console.log(responsecodes.data.records,"responsecodes")
			var dealer_courier_code = responsecodes.data.records
			var dealerCoords = {}
			var dealerCities = {}
			dealer_courier_code.forEach((each)=>{
				if(each.hasOwnProperty("consignee_lat") && each.hasOwnProperty("consignee_lng")){
					var {consignee_code,
						consignee_lat,
						consignee_lng,
						consignee_city
					} = each;
					
					dealerCoords[consignee_code] = {
						lat : consignee_lat,
						lng : consignee_lng,
					}

					dealerCities[consignee_code] = consignee_city
				}
			})
			console.log("dealerCities",dealerCities);
			console.log("dealerCoords",dealerCoords);
			var dealercouriercode = []
			dealer_courier_code.map((e) => {
				if(e.consignee_code != "" && e.consignee_code != undefined)
				{
					dealercouriercode.push({ value: e.consignee_code, label: e.consignee_code })
				}
			})
			console.log(dealercouriercode,"dealercouriercode")
			this.setState({
				dealercouriercode:dealercouriercode,
				dealerCoords:dealerCoords,
				dealerCities : dealerCities
			});
		})
		console.log(this.state.dealercouriercode,"dealercouriercode1")
		console.log(this.state.dealercouriercity,"dealercouriercity1")

		redirectURL.post("/dashboard/getPlantCodesForCourier")
		.then((responseplantcode) => {
			var plant_courier_codes = responseplantcode.data.records
			console.log(plant_courier_codes,"plant_courier_codes")
			var plantcouriercodes = []
			var allPlantsCoords = {}
			var allPlantAddresses = {}
			plant_courier_codes.forEach((each)=>{
				var {plant_code,plant_coordinates,plant_name} = each;
				allPlantsCoords[plant_code] = plant_coordinates;
				allPlantAddresses[plant_code] = plant_name;
			})

			plant_courier_codes.map((e) => {
				if(e.plant_code != "" && e.plant_code != undefined)
				{
					plantcouriercodes.push({ value: e.plant_code, label: e.plant_code })
				}
			})
			console.log(plantcouriercodes,"plantcouriercodes")
			console.log(allPlantAddresses,"allPlantAddresses")
			this.setState({
				plantcouriercodes:plantcouriercodes,
				allPlantsCoords:allPlantsCoords,
				allPlantAddresses:allPlantAddresses
			});
		})
		console.log(this.state.plantcouriercodes,"plantcouriercodes1")

		if(this.props.match.path == "/courierconsignments")
		{
			urlpath = '/consignments/couriers';
			counttype='all';
			
			var preqparams = {
				from_date:fromdate,
				to_date:todate,
				consigner_code:[{"value":'all',"label":"All"}],
				serviceProviders : serviceProviders,
				is_filter_call : 0,
			}

			if (courierConsigneeCode != "" && courierConsigneeCode != null && courierConsigneeCode != undefined) {
				preqparams.courierConsigneeCode = courierConsigneeCode
			}

			this.setState({
				counttype:counttype,
				loadshow:'show-m',
				from_date:fdate,
				to_date:tdate,

			});
			console.log("preqparams ", preqparams)
			// redirectURL.post("/consignments/summarysecondleveldata",{dept_code:encode("LOG-PRT (Courier)")})
			// .then(async (res) =>{
			// 	console.log("res.data.message[0] ",res.data)
			// 	var criticaldata=res.data.message[0].critical_data;
				this.setState({
					pageTitle : "Courier Consignments (All)",
					// critical_data : criticaldata
				});
				this.onLoadShowCouriers(preqparams);
			// }).catch((e)=>{
			// 	console.log(e);
			// });
			
			
		}
		else if(this.props.match.path == "/activecourierconsignments")
		{
			urlpath = '/consignments/activecouriers';
			counttype='active';
			this.setState({
				pageTitle : "Courier Consignments (Active)"
			})
			var preqparams = {
				from_date: fromdate,
				to_date: todate,
				consigner_code:[{"value":'all',"label":"All"}],
				serviceProviders : serviceProviders,
				is_filter_call : 0,
			}
			
			if (courierConsigneeCode != "" && courierConsigneeCode != null && courierConsigneeCode != undefined) {
				preqparams.courierConsigneeCode = courierConsigneeCode
			}
			// redirectURL.post("/consignments/summarysecondleveldata",{dept_code:encode("LOG-PRT (Courier)")})
			// .then(async (res) =>{
			// 	console.log("res.data.message[0] ",res.data)
			// 	var criticaldata=res.data.message[0].critical_data;
			// 	this.setState({
			// 		counttype:counttype,
			// 		loadshow:'show-m',
			// 		from_date:fdate,
			// 		to_date:tdate,
			// 		pageTitle : "Courier Consignments (Active)",
			// 		critical_data : criticaldata
			// 	});
			// 	this.onLoadShowCouriers(preqparams);
			// }).catch((e)=>{
			// 	console.log(e);
			// });
			this.setState({
				counttype:counttype,
				loadshow:'show-m',
				from_date:fdate,
				to_date:tdate,

			});
			this.onLoadShowCouriers(preqparams);
			
		}
		else if(this.props.match.path == "/deliveredcourierconsignments")
		{
			urlpath = '/consignments/deliveredcouriers';
			counttype='deliver';
			this.setState({
				pageTitle : "Courier Consignments (Delivered)"
			})
			var preqparams = {
				from_date:fromdate,
				to_date:todate,
				consigner_code:[{"value":'all',"label":"All"}],
				serviceProviders : serviceProviders,
				is_filter_call : 0,
			}

			if (courierConsigneeCode != "" && courierConsigneeCode != null && courierConsigneeCode != undefined) {
				preqparams.courierConsigneeCode = courierConsigneeCode
			}

			this.setState({
				counttype:counttype,
				loadshow:'show-m',
				from_date:fdate,
				to_date:tdate,

			});
			this.onLoadShowCouriers(preqparams);
		}
		else if(this.props.match.path == "/invalidcourierconsignments")
		{
			urlpath = '/consignments/getInvalidCourierDataByParams';
			counttype='invalid';
			this.setState({
				pageTitle : "Courier Consignments (Invalid)"
			})
			var preqparams = {
				from_date:fromdate,
				to_date:todate,
				consigner_code:[{"value":'all',"label":"All"}],
				serviceProviders : serviceProviders,
				is_filter_call : 0,
			}

			if (courierConsigneeCode != "" && courierConsigneeCode != null && courierConsigneeCode != undefined) {
				preqparams.courierConsigneeCode = courierConsigneeCode
			}

			this.setState({
				counttype:counttype,
				loadshow:'show-m',
				from_date:fdate,
				to_date:tdate,

			});

			this.onLoadShowCouriers(preqparams);
		}
		else if (this.props.match.path == "/billingreportcourierconsignments"){
		urlpath = '/consignments/couriers';
		counttype='all';
		
		var preqparams = {
			from_date:fromdate,
			to_date:todate,
			consigner_code:[{"value":'all',"label":"All"}],
			serviceProviders : serviceProviders,
			is_filter_call : 0,
		}

		if (courierConsigneeCode != "" && courierConsigneeCode != null && courierConsigneeCode != undefined) {
			preqparams.courierConsigneeCode = courierConsigneeCode
		}

		this.setState({
			counttype:counttype,
			loadshow:'show-m',
			from_date:fdate,
			to_date:tdate,

		});
		// redirectURL.post("/consignments/summarysecondleveldata",{dept_code:encode("LOG-PRT (Courier)")})
		// .then(async (res) =>{
		// 	console.log("res.data.message[0] ",res.data)
		// 	var criticaldata=res.data.message[0].critical_data;
			this.setState({
				pageTitle : "Billing Report",
				// critical_data : criticaldata
			});
			this.onLoadShowCouriers(preqparams);
		}
		
		// preqparams['is_filter_call'] = 0;
		
	};

	onLoadShowCouriers(parameters)
	{
		this.setState({
			loadshow:'show-m',
			overly : "show-m",
		})
		var urlpath;
		

		if(this.props.match.path == "/courierconsignments")
		{
			urlpath = '/consignments/couriers';
		}
		else if(this.props.match.path == "/activecourierconsignments")
		{
			urlpath = '/consignments/activecouriers';
		}
		else if(this.props.match.path == "/deliveredcourierconsignments")
		{
			urlpath = '/consignments/deliveredcouriers';
		}
		else if(this.props.match.path == "/invalidcourierconsignments")
		{
			urlpath = '/consignments/getInvalidCourierDataByParams';
		}
		else if (this.props.match.path == "/billingreportcourierconsignments")
		{
			console.log("this line")
			urlpath = '/consignments/couriers';
		}
		console.log("urlpath ", urlpath);

		console.log("parameters ", parameters);

	    redirectURL.post(urlpath,parameters)    
		  .then((response) => {
			var records = response.data.consignments;
			
			// console.log("records1729", records);
			var intransitarr=[];
			var priorityarr=[];
			var outdeliverarr = [];
			var deliverarr = [];
			var forcearr = [];
			var datanaarr = [];
			var confirmDelivery=[]
			records.map((item) => {
				
				//In Transit
				if(item.consignment_status == 'In Transit')
				{
					intransitarr.push(item);
				}

				//Out of Delivery
				if(item.consignment_status == 'Out For Delivery')
				{
					outdeliverarr.push(item);
				}

				//Delivered
				if(item.consignment_status == 'Delivered')
				{
					deliverarr.push(item);
				}

				if(item.consignment_status == 'Force Closed')
				{
					forcearr.push(item);
				}
				//Data Not Available
				if(item.consignment_status == '' || item.consignment_status == null || item.consignment_status == "Invalid docket")
				{	
					// Change later
					// datanaarr.push(item);
				}
				if(item.tpt_confirm_delivery == 1)
				{
					confirmDelivery.push(item);
				}
				//Priority
				if(item.is_priority == 1)
				{
					priorityarr.push(item)
				}
				
			});
			let newArray = []; 
            // console.log("item.consignment_status",outdeliverarr)
			// Declare an empty object 
			let uniqueObject = {}; 
			var objTitle;
			// Loop for the array elements 
			for (let i in records) { 
	
				// Extract the title 
				objTitle = records[i]['consigner_code']; 
	
				// Use the title as the index 
				uniqueObject[objTitle] = records[i]; 
			} 
			
			// Loop to push unique object into array 
			for (var a in uniqueObject) { 
				newArray.push(uniqueObject[a]); 
			} 
			
			console.log("newArray1729", newArray)
			if(parameters.is_filter_call == 0)
			{
				// var consigners=[];
				// newArray.map((ccode) => {
				// 	consigners.push(ccode.consigner_code)
				// })
				// console.log(consigners , "consigners1729")
				const plantList = response.data.plantList;
				plantList.unshift({value : 'all', label: 'ALL'})			
				var consigners = plantList
			}
			else{
				consigners = this.state.consigners
			}

			
			//console.log("records ", records)
			if(this.props.match.path == "/invalidcourierconsignments")
			{
				var allrecords = datanaarr;
			}
			else
			{
				var allrecords = records;
			}
			// console.log(allrecords , "allreacords");
			var critical_counter_data = []
			if(urlpath == "/consignments/couriers" || urlpath == '/consignments/activecouriers')
			{
				//console.log(this.state.critical_data,"critical data length")
				this.state.critical_data.map(function(e,index){
					
					allrecords.map(function(k){
						if(e.consignment_code == k.consignment_code && getHyphenYYYYMMDDHHMMSS(k.mgpa_date_time) == e.mgpa_date_time)
						{
							k['critical_record'] = 1;
							critical_counter_data.push(k);
						}
					})
				})
			}

			//console.log(consigners,"consigners");
			var cancelled_data = "Not Applicable"
			if(this.props.match.path == "/courierconsignments" || this.props.match.path == "/deliveredcourierconsignments")
			{
				cancelled_data = allrecords.filter(function(e){
					return e.cancelled == 1
				})
			}
			var pod_received_data = []
			var trip_closed_by_pod = []
			if(this.props.match.path == "/courierconsignments" || this.props.match.path == "/activecourierconsignments" 
			|| this.props.match.path == "/deliveredcourierconsignments")
			{
				pod_received_data = allrecords.filter(function(e){
					return e.pod_received == 1
				})
				trip_closed_by_pod = allrecords.filter(function(e){
					return e.trip_closed_by_pod == 1
				})
			}
			
			var on_time_data =[]
			if(this.props.match.path == "/deliveredcourierconsignments")
			{
				on_time_data = allrecords.filter(e => {
					if(e.delivery_datetime != "" && e.delivery_datetime != undefined && e.expected_eta != "" && e.expected_eta != undefined)
					{
						if ((new Date(e.delivery_datetime) <= new Date(e.expected_eta))|| e.is_valid_remark == 1) {
							return e
						}
					}
                })
			}
			var delayed_data = []
			if(this.props.match.path == "/deliveredcourierconsignments")
			{
				
					delayed_data = allrecords.filter(e => {
						if(e.delivery_datetime!="" && e.delivery_datetime !=undefined  && e.expected_eta != "" && e.expected_eta != undefined)
						{
							if (((new Date(e.delivery_datetime) > new Date(e.expected_eta))&& !e.is_valid_remark ==1)|| e.is_valid_remark == 0 ) {
								return e
							}
						}
					})
			}
			if(this.props.match.path == "/billingreportcourierconsignments") {

			}
			// allrecords = allrecords.filter(e => e.service_provider != 'spoton logistics pvt ltd')
			// allrecords = allrecords.filter(e => e.consignment_status != "Invalid docket")
			allrecords = allrecords.filter(e => e.hasOwnProperty("consignment_status"))
			console.log(allrecords,"allrecords1729")
            this.setState({
				consigners:consigners,
				rowData:allrecords,
				originaldata:allrecords,
				intransit:intransitarr,
				outdeliver:outdeliverarr,
				deliver:deliverarr,
				datana:datanaarr,
				prioritydata:priorityarr,
				intransitcount:intransitarr.length,
				outdelivercount:outdeliverarr.length,
				delivercount:deliverarr.length,
				datanacount:datanaarr.length,
				totalcount:allrecords.length,
				prioritydatacount:priorityarr.length,
				confirmDelivery:confirmDelivery,
				critical_counter_data : critical_counter_data,
				overly : "show-n",
				loadshow:'show-n',
				cancelled_data: cancelled_data,
				pod_received_data: pod_received_data,
				trip_closed_by_pod: trip_closed_by_pod,
				on_time_data:on_time_data,
				delayed_data:delayed_data,
				on_time_data_count:on_time_data.length,
				delayed_data_count:delayed_data.length,
				forceclosecount:forcearr,
				force_close_count:forcearr.length
            });
		  })
	}
	
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };

	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);

		   
		   
	}
	onRowSelection(event){
		console.log("row selected")
		var containerslist=[];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
			//var containerlist=[];
			console.log("rowselect", event.data)
			var rowCount =  event.data
			//console.log("rowCount ", rowCount)
			
			rwCount.map((item) => {
				containerslist.push(item.data.truck_no)
			});
			var deliverStatusValue;
			if(event.data.status == 1)
			{
				deliverStatusValue = "Inside Plant"
			}
			else if(event.data.status == 2)
			{
				deliverStatusValue =  "In Transit"
			}
			else if(event.data.status == 3)
			{
				deliverStatusValue =  "Near Destination"
			}
			else if(event.data.status == 4)
			{
				deliverStatusValue = "Reached Destination"
			}	
			
			else if(event.data.status == 5)
			{
				deliverStatusValue =  "Left Destination"
			}					
			else if(event.data.status == 6)
			{
				deliverStatusValue =  "Force Closed"
			}
			else{
				deliverStatusValue =  ""
			}
			this.setState({
				containerslist:containerslist,
				deliveryStatus : event.data.status,
				deliverStatusValue : deliverStatusValue,
				distance_from_dealer_location : event.data.distance_from_dealer_location,
				deliveredDeptCode : event.data.dept_code,
				deliveredTruckNo : event.data.truck_no,
			})
			 console.log("checkedlist ", containerslist)
		// }
		// else{
		// 	if(containerslist.length > 0)
		// 	{
		// 		containerslist=[]
		// 		this.setState({
		// 			containerslist:containerslist
		// 		})
		// 	}
		// }
		
	}
	renderMap = () => {    	
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	
	initMap = () => {
    	console.log("mapinfo ",this.state.mapinfo);
    	var currentwindow;

		var jsondata = this.state.mapinfo;
		var locations = jsondata.breaks;
		var arr = jsondata.coords;

		console.log("Here",arr);
		if(arr.length > 0)
		{
			var lt=arr[0].lat;
			var ln=arr[0].lng;
		}
		else{
			if(this.state.defTransitCoords == '')
			{
				var lt=28.4519751;
				var ln=77.0310713;
			}
			else{
				var lt=JSON.parse(this.state.defTransitCoords).lat;
				var ln=JSON.parse(this.state.defTransitCoords).lng;
			}
			
		}
        var routeinfo = jsondata.route_details;
    	 var mapOptions = {
    			zoom: 7,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
    	          labels:true,
    	          mapTypeControlOptions: {
    	            mapTypeIds: ['hybrid', 'roadmap'],
    	          },
    	          center: new window.google.maps.LatLng(lt,ln),
    	          mapTypeId: window.google.maps.MapTypeId.ROADMAP
    	        };
    	        
    	        var map = new window.google.maps.Map(document.getElementById('map'),
    	            mapOptions);

				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(lt,ln));
    			 // Create our info window content
    			var currentinfowindow = null;
    	       var line=new window.google.maps.Polyline(
    	              {
    	                map:map,
    									strokeColor: '#157254',
    									strokeOpacity: 1.0,
    									strokeWeight: 2.5,
    	                		icons: [{
    	                          icon: {
    	                                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                  strokeColor:'#ff8c52',
    	                                  fillColor:'#ff8c52',
    	                                  fillOpacity:1,
    									  strokeWeight: 2
    	                                },
    	                          repeat:'100px',
    	                          path:[]
    	                       }]
    	                 });

			if(arr.length > 0)
			{
    		  for (let i = 0; i < arr.length; i++) {
    				var path=line.getPath().getArray();
    					
    						let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    			
    	          //Change line color based on map type
    	        window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
    	            if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#FFFFFF',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });                
    	            }
    	            else
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#157254',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });

    	            }

    	              for (i = 0; i < arr.length; i++) {
						console.log(arr[i.lat] , arr[i.lng])
    	                    var path=line.getPath().getArray();
    	                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    	                            //map.setCenter(latLng);
    	                  }
    	                            
    	             var x = map.getZoom();
    	             var c = map.getCenter();
    	             window.google.maps.event.trigger(map, 'resize');
    	             map.setZoom(x);
    	             map.setCenter(c);
				} );
				
				bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
    		  }

		  var infowindow = new window.google.maps.InfoWindow();
			var marker;
			
			var routeLegs = this.state.mapinfo.route_details.legs
			for(let leg of routeLegs){
				if(leg.hasOwnProperty("location_lng") && leg.hasOwnProperty("location_lat")){
					marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(parseFloat(leg.location_lat), parseFloat(leg.location_lng)),
						icon:require('../../assets/icons/gmarker.png'),
						map: map,
					});

					window.google.maps.event.addListener(marker, 'click', (function(marker) {
						return function() {
							var contentString = "<h5 class='g-header' style='padding:0px'><p><img src="+marker.icon+">"+"<B style=';'>"+leg.intransit_location+"</B></p><h5><p style='padding-left:10px'><B> Status : </B>"+leg.intransit_status+"</p><p style='padding-left:10px'><B> Transit Date : </B>"+leg.intransit_date+"</p>";	
							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							}
						})(marker));
				}

			}
			marker = new window.google.maps.Marker({
		        position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
		  		// icon:require('../../assets/icons/track_start.png'),
		  		icon:require('../../assets/icons/business.png'),
		        map: map,	
		      });
	   	   window.google.maps.event.addListener(marker, 'click', (function(marker) {
	   	        return function() {
	   	        	//	console.log(clusters[0])
	   	         	
	       		    var contentString = "<h5 class='g-header' style='padding:0px'> <p ><img src="+marker.icon+">"+"<B  style='padding-left: 10px;'>"+routeinfo.plant_name+"</B></p> </h5><p style='padding-left:10px,color:#1F749B'><B>Started at :</B>"+routeinfo.start_time+"</p>";
	   	        	
	   			  infowindow.setContent(contentString);
	   			  currentwindow = infowindow;
	   			  infowindow.open(map, marker);
	   			  //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
	   			}
	   		})(marker));
	   	  marker = new window.google.maps.Marker({
	        position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
	  		icon:require('../../assets/icons/customer_pin.png'),
	  		// icon:require('../../assets/icons/truck-end.png'),
	        map: map,	
	      });
		   window.google.maps.event.addListener(marker, 'click', (function(marker) {
		        return function() {
		        	//	console.log(clusters[0])
		         	
	   		    var contentString = "<h5 class='g-header' style='padding:0px'><p><img src="+marker.icon+">"+"<B  style='padding-left: 10px;'>"+routeinfo.dealerCity+"</B></p></h5><p style='padding-left:10px,color:#1F749B''><B>End at :</B>"+routeinfo.end_time+"</p>";
				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				 // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker));
		}
		map.fitBounds(bounds)
	}
	onClickForceClose = (params) =>{
		//console.log("Pareas ", params)
		this.setState({
		   forceCloseRowNode : params,
			forceclosedata:params.data,
			sliderForceCloseTranslate:"slider-translate",
			overly:'show-m'
		})
	}
	onClickMarkDelivered = (params) =>{
		console.log(params.data);
		if(params.data.tpt_delivery_date != undefined && params.data.tpt_delivery_date != null && params.data.tpt_delivery_date != "")
		{
			var markdate = params.data.tpt_delivery_date.split("T")[0];
			this.setState({
				markDeliverDefault : markdate
			});
		}
		else{
			console.log("else")
			this.setState({
				markDeliverDefault : ""
			});
		}
		this.setState({
			 markDeliverRowNode : params,
			 markDeliverData:params.data,
			 sliderMarkDeliverTranslate:"slider-translate",
			 overly:'show-m',
		 })
	}

	onClickGetRemarks = (params) => {
		// console.log(params.data)
		this.setState({
			sliderRemarksTranslate:"slider-translate-30p",
			remarks_row_data : params.data
			
		})
	}

	// onChangeRemarks = (event) => {
	// 	this.setState({
	// 		remarksInput:event.target.value
	// 	})
	// }

	formCourierDelayRemarks = (event) => {
		event.preventDefault()
		const remarks = $("#courier_delayed_remarks").val()
		if (remarks != ""){

			const id = this.state.remarks_row_data._id
			
			// const remarks = this.state.remarksInput
			// .setRowData(this.state.critical_counter_data);
			let newRowData = this.state.rowData.map(e =>{
				if(e._id == id) {
					e.courier_dealay_remarks = remarks
				}
				return e
			})
			// console.log(remarks,"remarks_row_data",this.state.remarks_row_data)
			this.gridApi.setRowData(newRowData)
			const query = {
				id,
				remarks,

			}
			redirectURL.post("/dashboard/updateCourierDelayRemarks" , query).then((response) => {
				$("#courier_delayed_remarks").val("")
				
				this.setState({
				
					remarksInput:"",
					show:true,
					basicTitle:"Remarks Updated",
					basicType:"success"
				})
			})
		}
	}


	closeAlert = () => {
        this.setState({
            show: false
        });
	}
	closePriorityAlert = () => {
        this.setState({
            showPriority: false
		});
		window.location.reload();
	}
	
	uploadBulkPriorityFormHandler(event)
	{
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkMdData = this.state.pCsvContent;
		console.log(bulkMdData,"bulkUploadPID");
		var consignment_code = []
		var flag = 0;
		bulkMdData.map((e) => {
			consignment_code.push(e)
		})
		if(consignment_code != "" && consignment_code != undefined)
		{
			var reqParams = {
				email : localStorage.getItem("email"),
				csvData : bulkMdData
			}
			redirectURL.post("/consignments/bulkUploadPriority",reqParams).then((response)=>{
				var bulkUploadPID = $("#bulkUploadPID").val(null)
				this.setState({
					basicTitle : "Success",
					basicType : "success",
					show : true,
					file : "",
					bulkPrioritySlide:"",
					bulkUploadPID:bulkUploadPID,
					overly:"show-n",
					loadshow : "show-n"
				})
				this.componentDidMount()
			}).catch((e)=>{
				console.log(e);
			})
			
		}
		else{
			this.setState({
				basicTitle : "Consignment_code must not be empty",
				basicType : "danger",
				show : true,
				file : "",
				pCsvContent : "",
			})
		}
	}
	uploadBulkForceFormHandler(event)
	{
		event.preventDefault();
	
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkData = this.state.csvcontent;
		console.log(bulkData);
		var flag = 0;
		var consignmentcode = []
		var forcereason = []
		var invoicedate = []
		bulkData.map(function(e){
			if(e.consignment_code != "" && e.consignment_code != undefined)
			{
				consignmentcode.push(e.consignment_code)
			}
			if(e.reason != "" && e.reason != undefined)
			{
				forcereason.push(e.reason)
			}
			if(e.invoice_date != "" && e.invoice_date != undefined)
			{
				invoicedate.push(e.invoice_date)
			}
		})
		console.log(consignmentcode,"consignmentcode")
		console.log(forcereason,"forcereason")
		console.log(invoicedate,"invoicedate")
		if(consignmentcode != "" && forcereason != "" && invoicedate != "")
		{
			if(bulkData.length > 0)
			{
				var reqParams = {
					email : localStorage.getItem("email"),
					csvData : bulkData
				}
				redirectURL.post("/consignments/bulkUploadForceClose",reqParams).then((response)=>{
					var bulkUploadID = $("#bulkUploadID").val(null)
					this.setState({
						basicTitle : "Success",
						basicType : "success",
						show : true,
						file : "",
						bulkForceCloseSlide:"",
						bulkUploadID:bulkUploadID,
						overly:"show-n",
						loadshow : "show-n"
					})
					this.componentDidMount()
				}).catch((e)=>{
					console.log(e);
				})
			}
			else
			{
				this.setState({
					basicTitle : "Invalid File",
					basicType : "warning",
					show : true
				})
			}
			
		}
		else{
			this.setState({
				basicTitle : "Consignment Code, Reason, Invoice Date fields must not be empty",
				basicType : "danger",
				show : true,
				file:"",
				csvData : "",
			})
		}
	}
	
	uploadBulkMarkDeliverHandler(event)
	{
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkMdData = this.state.mdCsvContent;
		var consignmentkey = []
		var delivery_date_time = $("#bulk_delivery_date_time").val();
		bulkMdData.map((e) => {
			consignmentkey.push(e)
			
		})
		//console.log(delivery_date_time)
		//console.log(bulkMdData);
		if(delivery_date_time != "__-__-____")
		{
			
			if(consignmentkey != "" && consignmentkey != undefined)
			{
				var reqParams = {
					email : localStorage.getItem("email"),
					deliveryDate : delivery_date_time,
					csvData : bulkMdData
				}
				redirectURL.post("/consignments/bulkUploadMarkDeliver",reqParams).then((response)=>{
					var bulkUploadMDID = $("#bulkUploadMDID").val(null)
					var delivery_date_time = $("#delivery_date_time").val("")
					this.setState({
						basicTitle : "Success",
						basicType : "success",
						show : true,
						file : "",
						bulkMarkDeliverSlide:"",
						bulkUploadMDID:bulkUploadMDID,
						delivery_date_time:delivery_date_time,
						overly:"show-n",
						loadshow : "show-n"
					})
					this.componentDidMount()
				}).catch((e)=>{
					console.log(e);
				})
				
			}
			else{
				this.setState({
					basicTitle : "Consignment Code must not be empty",
					basicType : "danger",
					show : true,
					file : "",
					mdCsvContent : "",
				})
			}
		}
		else{
			// $("#bulkUploadMDID").val();
			this.setState({
				basicTitle : "Delivery Date is Not Selected",
				basicType : "danger",
				show : true,
				file : "",
				mdCsvContent : "",
			})
		}
	}
	
    onShowCourierTrack = async (params) => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.trackCourier,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        if(params != 0)
		{
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							
							{ 
                                headerName: "Transit Date",
                                field: "intransit_date", 
                                valueGetter:function(params){
									console.log("IN onShowCourierTrack, params.data = ", params.data);
                                  // var intransitdate=getDDMMYYYY(params.data.intransit_date)+" "+params.data.intransit_time
                                   var intransitdate= params.data.intransit_date +" "+ params.data.intransit_time
								   return intransitdate
								},
								filter:true,
								resizable:true
                            },
							{ 
                                headerName: "Location",
                                field: "intransit_location", 
                                filter:true,
								resizable:true 
                            },
							{ 
                                headerName: "Status",
                                field: "intransit_status", 
                                filter:true ,
								resizable:true
                            }
						]							
					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/consignments/trackcourier', {
							consignment_code:params.data.consignment_code
						})
						.then(async (response) =>{
                            //console.log("Step 1 ",response.data)
                            //console.log("test ", response);
                            var trackData=response.data;
                            //console.log("trackData ", trackData)
                            if(trackData.status == 'failure')
                            {
                                params.successCallback([]);
                            }
                            else{
                                params.successCallback(trackData);
                            }
							
							//console.log("Step 2 ",response.data)
							
						});
						 
					},
					masterDetail: true
				}
			});
			
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		//console.log("params.column.colId ", params.column)
		if(params.column.colDef.field == '_id')
		{
			params.node.setExpanded(!params.node.expanded);
		}
		else{

			params.node.setExpanded(false);
		}
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
	// onClickCounterShowData(ctype){
	// 	console.log("CType ", ctype);
	// }
	resetUpload = () => {
		this.setState({
			uploadDivWidth:'0%',
			bulkPrioritySlide:'',
			bulkForceCloseSlide:'',
			bulkMarkDeliverSlide:'',
			showDiv:'show-n',
			overly:'show-n',
			file:''
		});
	}
	onCloseDeliverModal = () => {
		this.gridApi.deselectAll();
		this.setState({ delivermodal: false, delDate:"none" });
	};
	// handlerDeliverDateTime = (event, currentDate, selectedDate) => {
    // 	var d = new Date(event._d);
    	
    // 	var deliverdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    // 	this.setState({
    // 		deliverdate:deliverdate
    // 	});
	// }
	handlerDeliverDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
		var deliverdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		var temp = deliverdate
		if(temp.split("-")[1]<10)
		{
			temp = temp.split("-")[0]+"-0"+temp.split("-")[1]+"-"+temp.split("-")[2]
		}

		var checkDeliveryDate = new Date(temp);
		var mgpa_date = this.state.selectedRow.mgpa_date_time;
		mgpa_date =new Date(mgpa_date.split("T")[0]);
		var currentdate = moment.parseZone(new Date()).format("YYYY-MM-DD");
		// console.log(checkDeliveryDate,mgpa_date,currentdate);
		var flag = 0;
		if(checkDeliveryDate < mgpa_date || checkDeliveryDate > new Date(currentdate))
		{
			flag = 1;
		}
		if(flag == 0)
		{
			console.log(deliverdate)
			this.setState({
				deliverdate:deliverdate
			});
		}
		else
		{
			this.setState({
				deliverdate:"",
				basicTitle : "Selected Date Must be Between MGPA Date and Current Date",
				basicType : "warning",
				show : true
			});
		}
    	
	}
	selectReason(){
		var rowdata = this.state.selectedRow;
		var arr = []
		arr.push({"label":"Delay in Pick up","value":"Delay in Pick up"});
		arr.push({"label":"Delay in transit/Network Delay","value":"Delay in transit/Network Delay"});
		arr.push({"label":"Delay due to Strike, Natural Calamity, Govt. Restrictions","value":"Delay due to Strike, Natural Calamity, Govt. Restrictions"});
		arr.push({"label":"Consignee Door Closed/Consignee Shifted","value":"Consignee Door Closed/Consignee Shifted"});
		arr.push({"label":"Consignee Refused to Accept","value":"Consignee Refused to Accept"});
		arr.push({"label":"Consignee asked to reschedule delivery","value":"Consignee asked to reschedule delivery"});
		arr.push({"label":"Incorrect Address/Pin Code","value":"Incorrect Address/Pin Code"});
		if(rowdata.vehicle_mode == "AIR CARGO")
		{
			arr.push({"label":"Consignment Offloaded due to DG goods/Oversize","value":"Consignment Offloaded due to DG goods/Oversize"});
			arr.push({"label":"Consignment offloaded by airlines","value":"Consignment offloaded by airlines"});
		}
		arr.push({"label":"Cartons short from VE","value":"Cartons short from VE"});
		arr.push({"label":"Cartons short received at Delivery Hub","value":"Cartons short received at Delivery Hub"});
		arr.push({"label":"Local Festival","value":"Local Festival"});
		arr.push({"label":"Manual Delivery/Dispatched via other Transporter","value":"Manual Delivery/Dispatched via other Transporter"});
		arr.push({"label":"Consignment delivered, awaiting confirmation from local branch","value":"Consignment delivered, awaiting confirmation from local branch"});
		arr.push({"label":"Invalid Docket (Correct GR no. to be specified)","value":"Invalid Docket (Correct GR no. to be specified)"});
		arr.push({"label":"Others (Please Specify)","value":"Others (Please Specify)"});
		return arr;
	}
	setSelectedReason = (event)=>{
		var selectedReason = event.value;
		if(selectedReason == "Invalid Docket (Correct GR no. to be specified)" || selectedReason == "Others (Please Specify)")
		{
			this.setState({
				displayOtherField : "show-m"	
			})
		}
		else
		{
			this.setState({
				displayOtherField : "show-n"
				
			})
		}
		if(selectedReason == "Manual Delivery/Dispatched via other Transporter" || selectedReason == "Consignment delivered, awaiting confirmation from local branch")
		{
			var delDate = "flex";
		}
		else
		{
			var delDate = "none";
		}
		this.setState({
			selectedDeliveryReason : event.value,
			delDate : delDate
		})
	}
	changeReasonHandler(e){
		this.setState({
			deliveredOtherReason : e.target.value
		})
	}
	formSetDeliveredDate(event){
        event.preventDefault();
        var deliverrow=this.state.deliverrow;
		var deliverdate = this.state.deliverdate;
		var tpt_delivery_reason = this.state.selectedDeliveryReason;
		var tpt_delivery_other_reason = this.state.deliveredOtherReason;
		var selectedDeliveryReason = this.state.selectedDeliveryReason;
		//console.log("deliverrow ", deliverrow);
		console.log(deliverdate);
		var dateFlag = 0;
		if(tpt_delivery_reason == "Manual Delivery/Dispatched via other Transporter" || tpt_delivery_reason == "Consignment delivered, awaiting confirmation from local branch")
		{			
			if(moment.parseZone(deliverdate).format("YYYY-MM-DD") == "Invalid date" ||moment.parseZone(deliverdate).format("YYYY-MM-DD") == "" || moment.parseZone(deliverdate).format("YYYY-MM-DD") == undefined)
			{
				var dateFlag = 1;
			}
		}
		
		var flag = 0;
		if(tpt_delivery_reason == "Others (Please Specify)")
		{
			if(tpt_delivery_other_reason.replace(/\s/g,"").length == 0)
			{
				flag = 1;
			}
		}
		
		if(flag == 1)
		{
			this.setState({
				basicTitle : "Other Reason Should not be Empty",
				basicType : "warning",
				show : true
			});
		}
		else
		{
			if(dateFlag == 1)
			{
				this.setState({
					basicTitle : "Invalid Delivery Date",
					basicType : "warning",
					show : true
				});
			}
			else
			{
				if(tpt_delivery_reason !=undefined && tpt_delivery_reason !='')
				{
					var delReason = tpt_delivery_reason;
				}
				else
				{
					var delReason = "Imvalid Docket (Correct GR no. to be specified)";
				}
				var reqparams = {
					tpt_confirm_delivery:1,
					dept_code : this.state.deliveredDeptCode,
					truck_no : this.state.deliveredTruckNo,
					email : localStorage.getItem("email"),
					tpt_delivery_date:moment.parseZone(deliverdate).format("YYYY-MM-DD"),
					consigment_code:deliverrow.data.consignment_code,
					tpt_delivery_reason : delReason,
					tpt_delivery_other_reason : tpt_delivery_other_reason
				}
				redirectURL.post("/consignments/setCouriersDeliverDate", reqparams)
				.then((response) => {
					console.log("resss ", response.data)
					if(response.data.ok == 1){
						this.setState({
							delivermodal:false,
							delDate:"none",
							deliverdate:'',
							deliveryStatus:"",
							deliveredDeptCode:"",
							deliveredTruckNo:"",
							overly : "show-m",
							loadshow :"show-m"
						});
						this.submitForm();
					}
				})
			}
		}
    }
	onClickCounterShowData(params){
		console.log("IN onClickCounterShowData",params);

		// this.setState({
		// 	overly : "show-m",
		// 	loadshow :"show-m"
		// })

		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}
			
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		if(params == "critical")
		{
			this.gridApi.setRowData(this.state.critical_counter_data);
			// this.gridApi.onFilterChanged();
		}
		if(params == "intransit")
		{
			var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["consignment_status"]) {
					return rec["consignment_status"] == "In Transit";
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		if(params == "confirmDelivery")
		{
			
			this.gridApi.setRowData(this.state.confirmDelivery);
			this.gridApi.onFilterChanged();
		}
		
		if(params == "outfordeliver")
		{
			var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			console.log(filterComponent);
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["consignment_status"]) {
					return rec["consignment_status"] == "Out for Delivery";
				}
			});
			console.log(isFilterDataAvailable);
			this.gridApi.setRowData(this.state.outdeliver);
			this.gridApi.onFilterChanged();
		}
		if(params == "deliver")
		{
			
			var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["consignment_status"]) {
					return rec["consignment_status"] == "Delivered";
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
			this.setState({
				showRemarksButton:false,
				// loadshow:"show-n",
				// overly:"show-n",
			})
		}
		if(params == "forceclose")
		{
			var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["consignment_status"]) {
					return rec["consignment_status"] == "Force Closed";
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		if(params == "ontime")
		{
			
			var on_time_data = this.state.rowData.filter(e => {
				if ((new Date(e.delivery_datetime) <= new Date(e.expected_eta))|| e.is_valid_remark == 1) {
					return e
				}
			})
			this.gridApi.setRowData(on_time_data);
			this.gridApi.onFilterChanged();
			this.setState({
				showRemarksButton:false,
				// loadshow:'show-n',
				// overly:"show-n",


			})
		}
		if(params == "delayed")
		{
		
			var delayed_data = this.state.rowData.filter(e => {
				if (((new Date(e.delivery_datetime) > new Date(e.expected_eta))&& !e.is_valid_remark ==1)|| e.is_valid_remark == 0) {
					return e
				}
			})
			this.gridApi.setRowData(this.state.delayed_data);
			this.gridApi.onFilterChanged();
			this.setState({
				showRemarksButton:true,
				// loadshow:'show-n',
				// overly:"show-n",
			})
		}
		if(params == "datana")
		{
			var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["consignment_status"]) {
					return (rec["consignment_status"] == "" || rec["consignment_status"] == null || rec["consignment_status"] == "Invalid docket");
				}
			});
			this.gridApi.setRowData(this.state.datana);
			this.gridApi.onFilterChanged();
		}

		if(params == "all")
		{
			var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			
			// filterComponent.applyModel();
			
			this.gridApi.setRowData(this.state.originaldata);
			this.gridApi.onFilterChanged();
		}
		if(params == "cancelled")
		{
			this.gridApi.setRowData(this.state.cancelled_data);
			this.gridApi.onFilterChanged();
		}
		if(params == "trip_closed")
		{
			this.gridApi.setRowData(this.state.trip_closed_by_pod);
			this.gridApi.onFilterChanged();
		}
		if(params == "pod")
		{
			this.gridApi.setRowData(this.state.pod_received_data);
			this.gridApi.onFilterChanged();
		}
		if(params == "priority")
		{
			this.gridApi.setRowData(this.state.prioritydata);
			this.gridApi.onFilterChanged();
		}
		
	}

	handlerStartDateTime = (selectDate) => {
		console.log("selectDate ", selectDate)
    	// var d = new Date(event._d);
    	
    	// var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	// this.setState({
    	// 	from_date:startdate
    	// });
	}
	handleChange = date => {
		this.setState({
		  to_date: date
		});
	  };
	  handleChange1 = date => {
		this.setState({
		  from_date: date
		});
	  };
	handlerEndDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		to_date:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
	}
	onClickHideAll(){
		var uploadfilebulk = $("#uploadfile").val(null)
		var bulkUploadPID = $("#bulkUploadPID").val(null)
		var bulkUploadID = $("#bulkUploadID").val(null)
		var bulkUploadMDID = $("#bulkUploadMDID").val(null)
		var delivery_date_time = $("#delivery_date_time").val("")
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderForceCloseTranslate:'',
			sliderMarkDeliverTranslate : "",
			sliderRemarksTranslate:"",
			bulkPrioritySlide:"",
			bulkForceCloseSlide:"",
			bulkMarkDeliverSlide:"",
			sliderCreateConsignment:"",
			sliderRso:"",
			uploadfilebulk:uploadfilebulk,
			bulkUploadPID:bulkUploadPID,
			bulkUploadID:bulkUploadID,
			bulkUploadMDID:bulkUploadMDID,
			delivery_date_time:delivery_date_time,
			sliderBulkRemarks:"",
			sliderBlukValidateRemarks:"",
			sliderBulkMarkDeliverd:"",
		});
		
	}
	saveConsignmentData = async (event) => {
		event.preventDefault();
		var plant_code = this.state.selectedPlantCode.value;
		var dealer_code = this.state.selectedDealerCode.value;
		var dealer_city = this.state.selectedDealerCity.value;
		// var plant_name = this.state.plant_code.label;
		var selectedService = this.state.selectedService.value
		var grno_consignment_code = this.state.grno_consignment_code;
		var courier_vehicle_mode = this.state.courier_vehicle_mode;
		var mgpa_datetime = $("#mgpa_datetime").val();
		var mgpa_number = this.state.mgpa_number;
		var transit_time = this.state.transit_time
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

		var gate_out_time = $("#gate_out_time").val();
		// if (this.state.consignee_code != "") {
		// 	var consignee_code = this.state.consignee_code.value;
		// 	var consignee_name = this.state.consignee_code.label;
		// }
		// else {
		// 	var consignee_code = "";
		// 	var consignee_name = "";
		// }
		// console.log(transit_time,"transit_time")
		// console.log(transit_time.length,"transit_time")
		if (plant_code != "" && grno_consignment_code != ""
			&& gate_out_time != "") {
			if(transit_time.length <= 2)
			{
				var params = {
					selectedservice:selectedService,
					consignment_code: grno_consignment_code,
					mgpa_datetime: mgpa_datetime,
					courier_vehicle_mode:courier_vehicle_mode,
					// gate_out_time: gate_out_time,
					// mgpa_number:mgpa_number,
					consignee_code: dealer_code,
					consigner_code: plant_code,
					consigner_city:dealer_city,
					transit_time:transit_time
				}
				// console.log("params ", params);
	
				redirectURL.post("/dashboard/saveCourierConsignmentData",params)
					.then((response) => {
						// console.log("save response ", response.data)
						if (response.data.status == "success") {
							var mgpadatetime = $("#mgpa_datetime").val("");
							this.setState({
								show: true,
								basicType: "success",
								basicTitle: "Successfully created consignment",
								sliderCreateConsignment:"",
								selectedService:"",
								mgpa_datetime: mgpadatetime,
								// gate_out_time: gate_out_time,
								// mgpa_number:mgpa_number,
								dealer_code: "",
								plant_code: "",
								dealer_city:"",
								transit_time:"",
								grno_consignment_code:"",
								selectedPlantCode:{ "value": "Select Plant Code", "label": "Select Plant Code" },
								selectedDealerCode:{ "value": "Select Dealer Code", "label": "Select Dealer Code" },
								selectedDealerCity:{ "value": "Select Dealer City", "label": "Select Dealer City" },
								courier_vehicle_mode:"",
								loadshow:"show-n",
								overly:"show-n",
							})
							this.componentDidMount()
						}
						else {
							if (response.data.message == "Special characters are not allowed") {
								this.setState({
									show: true,
									basicType: "danger",
									basicTitle: "Invalid Data"
								})
							}
							if (response.data.message == "Consigment Already Exists") {
								this.setState({
									show: true,
									basicType: "danger",
									basicTitle: "Consigment Already Exists"
								})
							}
							else {
								this.setState({
									show: true,
									basicType: "danger",
									basicTitle: "Failed to create consignment"
								})
							}
	
						}
					})
			}
			else {
				this.setState({
					show: true,
					basicType: "danger",
					basicTitle: "Transit Time should be only two digits"
				})
	
			}		
		}
		else {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "All fields are mandatory"
			})

		}
	}
	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	onBulkPriorityBtn(){
		this.setState({
			overly : "show-m",
			bulkPrioritySlide : "slider-translate"
		})
	}
	onBulkForceCloseBtn(){
		this.setState({
			overly : "show-m",
			bulkForceCloseSlide : "slider-translate"
		})
	}
	onBulkMarkDeliverBtn(){
		this.setState({
			overly : "show-m",
			bulkMarkDeliverSlide : "slider-translate"
		})
	}
	
	formForceCloseHandler(event){
		event.preventDefault()
		var consignmentId = this.state.forceclosedata.consignment_code;
		var forceCloseReason = $("#forceClosureResonID").val();
		var email = localStorage.getItem("email");
		// console.log(forceCloseReason,email);
		var reqParams = {
			consignmentId : consignmentId,
			forceCloseReason : forceCloseReason,
			email : email
		}
		if(forceCloseReason.replace(/\s/g,"").length)
		{
			redirectURL.post("/consignments/updateCourierForceClosure",reqParams).then((response)=>{
				this.setState({
					sliderForceCloseTranslate : "",
					loadshow : "show-n",
					overly:"show-n",
					basicTitle : "Force Closed Successfully",
					basicType : "success",
					show : true,
					forceCloseRowNode : "",
					forceclosedata:"",
				});
				window.location.reload();
			}).catch((e)=>{
				console.log(e)
			})
		}
		else
		{
			this.setState({
				basicTitle : "Reason Should not be Empty.",
				basicType : "danger",
				show : true,
			});
		}
		

	}
	formMarkDeliverHandler(event)
	{
		event.preventDefault()
		var consignmentId = this.state.markDeliverData.consignment_code;
		var delivery_date_time = $("#delivery_date_time").val();
		console.log(delivery_date_time)
		if(delivery_date_time != "__-__-____")
		{
			var rowdata = this.state.markDeliverData;

			var temp = delivery_date_time
			if(temp.split("-")[1].length < 2)
			{
				temp = temp.split("-")[2]+"-0"+temp.split("-")[1]+"-"+temp.split("-")[0]
			}
			else
			{
				temp = temp.split("-")[2]+"-"+temp.split("-")[1]+"-"+temp.split("-")[0]
			}

			var flag=0;
			var checkDeliveryDate = new Date(temp);
			var mgpa_date = this.state.markDeliverData.mgpa_date_time;
			mgpa_date =new Date(mgpa_date.split("T")[0]);
			var currentdate = moment.parseZone(new Date())
			console.log(new Date(currentdate),mgpa_date,checkDeliveryDate);
			if(checkDeliveryDate < mgpa_date)
			{
				flag = 1;
			}
			if(flag == 0)
			{
				var email = localStorage.getItem("email");
				var reqParams = {
					consignmentId : consignmentId,
					delivery_datetime  : delivery_date_time,
					email : email
				}

				console.log(reqParams);
				redirectURL.post("/consignments/courierMarkDelivered",reqParams).then((response)=>{
					this.setState({
						sliderMarkDeliverTranslate : "",
						loadshow : "show-n",
						overly:"show-n",
						basicTitle : "Marked as Delivered",
						basicType : "success",
						show : true,
						markDeliverRowNode : "",
						markDeliverRowNode:"",
					})
					window.location.reload();
				}).catch((e)=>{
					console.log(e)
				})
			}
			else
			{
				this.setState({
					deliverdate:"",
					basicTitle : "Selected Date Must be Between greater than MGPA Date",
					basicType : "warning",
					show : true
				});
			}
		}
		else
		{
			this.setState({
				basicTitle : "Delivery Date is Not Selected.",
				basicType : "danger",
				show : true
			})
		}
	}

	changeConsignerArrayHandler = consigner =>{
        this.setState(
	      { consigner },
	      () => console.log(`Consigner Option selected:`, this.state.consigner)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}
	selectConsignerOptionsItems(){
        let items = [];     
		items.push({value:'all',label:'All'}); 
		this.state.consigners.forEach(item =>{
			items.push({value:item,label:item}); 
		});
        
        return items;
	}
	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }
		if(e.target.files.length > 0){

		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'reason',
					inputName: 'reason',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'invoice_date',
					inputName: 'invoice_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				

			
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			console.log(csvData)
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []
		
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
		});
		console.log(contentList);
		this.setState({
			file:e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
			// console.log(fileData);
			// console.log(typeof(fileData))
			
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				file:"",
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
	}
		
	}
	changeFileHandlerFc = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }
		if(e.target.files.length > 0){

		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'service_provider',
					inputName: 'service_provider',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'consigner_code',
					inputName: 'consigner_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'consignee_code',
					inputName: 'consignee_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'consignee_city',
					inputName: 'consignee_city',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				// { 
				// 	name: 'mgpa_no',
				// 	inputName: 'mgpa_no',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				{ 
					name: 'transit_time',
					inputName: 'transit_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'mgpa_date_time',
					inputName: 'mgpa_date_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				// { 
				// 	name: 'gate_out_time',
				// 	inputName: 'gate_out_time',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				{ 
					name: 'vehicle_mode',
					inputName: 'vehicle_mode',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'order_type',
					inputName: 'order_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			console.log(csvData)
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);

		var contentList = []
		try
		{
			var out = new Promise(function(reject, resolve){
				var reader =  new FileReader();
				reader.onload = async function(e) {
					var contents = await e.target.result;
					resolve(contents);
				};
				if(e.target.files[0] != "" && e.target.files[0] != undefined)
				{
					var tt =  reader.readAsText(e.target.files[0]);
				}
			});
			console.log(contentList);
			this.setState({
				file:e.target.files[0]
			});
			// console.log("e.target.files[0].type ", e.target.files[0]);
	
			if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
			{
				// console.log(fileData);
				// console.log(typeof(fileData))
				
			}
			else{
				e.target.value = null;
				this.setState({
					uploadFile:'',
					file:"",
					show: true, 
					basicType:'danger', 
					basicTitle:'Please upload file having extensions .csv only.',
				});
			}
	
		}
		catch{

		}
	}
	}

	changeFileHandlerFc1 = async (e) => {
		const config = {
			headers:[
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'order_type',
					inputName: 'order_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'courier_dealay_remarks',
					inputName: 'courier_dealay_remarks',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}

		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			console.log(csvData)
			this.setState({
				csvcontentforremarks:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []
		try
		{
			var out = new Promise(function(reject, resolve){
				var reader =  new FileReader();
				reader.onload = async function(e) {
					var contents = await e.target.result;
					resolve(contents);
				};
				if(e.target.files[0] != "" && e.target.files[0] != undefined)
				{
					var tt =  reader.readAsText(e.target.files[0]);
				}
			});
			console.log(contentList);
			this.setState({
				file:e.target.files[0]
			});
			// console.log("e.target.files[0].type ", e.target.files[0]);
	
			if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
			{
				// console.log(fileData);
				// console.log(typeof(fileData))
				
			}
			else{
				e.target.value = null;
				this.setState({
					uploadFile:'',
					file:"",
					show: true, 
					basicType:'danger', 
					basicTitle:'Please upload file having extensions .csv only.',
				});
			}
	
		}
		catch{

		}
		
	}

	changeFileHandlerValidateRemarks = async (e) => {
		const config = {
			headers:[
				{ 
					name: 'consignment code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'order type',
					inputName: 'order_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'courier delay remarks',
					inputName: 'courier_delay_remarks',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'uploaded by',
					inputName: 'uploaded_by',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'valid remark',
					inputName: 'valid_remark',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}

		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			console.log(csvData)
			this.setState({
				csvcontentforvalidateremarks:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []
		try
		{
			var out = new Promise(function(reject, resolve){
				var reader =  new FileReader();
				reader.onload = async function(e) {
					var contents = await e.target.result;
					resolve(contents);
				};
				if(e.target.files[0] != "" && e.target.files[0] != undefined)
				{
					var tt =  reader.readAsText(e.target.files[0]);
				}
			});
			console.log(contentList);
			this.setState({
				file:e.target.files[0]
			});
			// console.log("e.target.files[0].type ", e.target.files[0]);
	
			if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
			{
				// console.log(fileData);
				// console.log(typeof(fileData))
				
			}
			else{
				e.target.value = null;
				this.setState({
					uploadFile:'',
					file:"",
					show: true, 
					basicType:'danger', 
					basicTitle:'Please upload file having extensions .csv only.',
				});
			}
	
		}
		catch{

		}
		
	}

	changeFileHandlerFcforMarkDeliverd = async (e) => {
		const config = {
			headers:[
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'order_type',
					inputName: 'order_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'delivery_date',
					inputName: 'delivery_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}

		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			console.log(csvData)
			this.setState({
				csvcontentformarkdelivered:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []
		try
		{
			var out = new Promise(function(reject, resolve){
				var reader =  new FileReader();
				reader.onload = async function(e) {
					var contents = await e.target.result;
					resolve(contents);
				};
				if(e.target.files[0] != "" && e.target.files[0] != undefined)
				{
					var tt =  reader.readAsText(e.target.files[0]);
				}
			});
			console.log(contentList);
			this.setState({
				file:e.target.files[0]
			});
			// console.log("e.target.files[0].type ", e.target.files[0]);
	
			if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
			{
				// console.log(fileData);
				// console.log(typeof(fileData))
				
			}
			else{
				e.target.value = null;
				this.setState({
					uploadFile:'',
					file:"",
					show: true, 
					basicType:'danger', 
					basicTitle:'Please upload file having extensions .csv only.',
				});
			}
	
		}
		catch{

		}
		
	}

	changeMDHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				mdCsvContent:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []
		
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
		});
		this.setState({
			file:e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
			// console.log(fileData);
			// console.log(typeof(fileData))
			
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				file:"",
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}
	changePriorityFileHandler = async (e) => {
		var dt = '';
		if(e.target.files.length > 0){
			const config = {
				headers: [
					{ 
						name: 'consignment_code',
						inputName: 'consignment_code',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
				]
			}
			var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					pCsvContent:csvData.data
				});
			})
			.catch(err => {})
			// console.log("e.target.files[0]",e.target.result);
			var contentList = []
			
			var out = new Promise(function(reject, resolve){
				var reader =  new FileReader();
				reader.onload = async function(e) {
					var contents = await e.target.result;
					resolve(contents);
				};
				var tt =  reader.readAsText(e.target.files[0]);
			});
			this.setState({
				file:e.target.files[0]
			});
			// console.log("e.target.files[0].type ", e.target.files[0]);
	
			if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
			{
			}
			else{
				e.target.value = null;
				this.setState({
					uploadFile:'',
					file:"",
					show: true, 
					basicType:'danger', 
					basicTitle:'Please upload file having extensions .csv only.',
				});
			}
		}
		
		
	}
	onClickShowDeliverPopup = (params) =>{
		var containerslist=[]
			var event = params
			var deliverStatusValue;
			if(event.data.status == 1)
			{
				deliverStatusValue = "Inside Plant"
			}
			else if(event.data.status == 2)
			{
				deliverStatusValue =  "In Transit"
			}
			else if(event.data.status == 3)
			{
				deliverStatusValue =  "Near Destination"
			}
			else if(event.data.status == 4)
			{
				deliverStatusValue = "Reached Destination"
			}	
			
			else if(event.data.status == 5)
			{
				deliverStatusValue =  "Left Destination"
			}					
			else if(event.data.status == 6)
			{
				deliverStatusValue =  "Force Closed"
			}
			else{
				deliverStatusValue =  ""
			}
			console.log(event.data);
			this.setState({
				containerslist:containerslist,
				deliveryStatus : event.data.status,
				deliverStatusValue : deliverStatusValue,
				distance_from_dealer_location : event.data.distance_from_dealer_location,
				deliveredDeptCode : event.data.dept_code,
				deliveredTruckNo : event.data.truck_no,
				selectedRow:event.data
			})
        this.setState({ 
            delivermodal: true, 
            deliverrow:params,
			deliverdate:''
		});
    }
	onClickShowForm() {
		this.setState({
			overly: "show-m",
			sliderCreateConsignment: "slider-translate",
		});
	}
	onClickShowForm1() {
		this.setState({
			overly: "show-m",
			sliderRso:"slider-translate"
		});
	}

	onClickShowForm2() {
		this.setState({
			overly: "show-m",
			sliderBulkRemarks:"slider-translate"
		})
	}

	onClickShowFormValidateRamrks() {
		this.setState({
			overly:"show-m",
			sliderBlukValidateRemarks:"slider-translate"

		})
	}

	onClickShowForm3() {
		this.setState({
			overly:"show-m",
			sliderBulkMarkDeliverd : "slider-translate",
		})
	}

	onSubmitBulkUpload = (event) => {
		event.preventDefault()

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Buk Upload",
				"action": "form",
				"label": "Buk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        console.log(this.state.csvcontent,"bulk uplosd dtaa")
        if(this.state.csvcontent != undefined && this.state.csvcontent != "")
        {
            if (this.state.csvcontent.length > 0) 
			{
				var duplicateconsignmentcode = []
				var transitdays = []
				var transitdayslength = true
				var invoicedate = []
				var dateCheck = false
				var specialCharcters = 0
				this.state.csvcontent.map((em) => {
					if(em.consignment_code != "" && em.consignment_code != undefined)
					{
						duplicateconsignmentcode.push(em.consignment_code)
					}
					if(em.transit_time != "" && em.transit_time != undefined)
					{
						transitdays.push(em.transit_time)
					}
					if(em.mgpa_date_time != "" && em.mgpa_date_time != undefined)
					{
						invoicedate.push(em.mgpa_date_time)
					}
				})
				// console.log(invoicedate,"invoicedate")
				transitdays.map((td) => {
					if(td.length > 2)
					{
						transitdayslength = false
					}
				})
				duplicateconsignmentcode.map((e) => {
					if (e.match(/[^a-zA-Z0-9]/g)) {
						specialCharcters = 1
					}
				})
				var checkDate = true;
				invoicedate.map((id) => {
					// console.log(id,"iddateeeee")
					var dateSplit = id.replace(/\//ig , "-")
					// console.log(dateSplit,"dateSplit1")
					dateSplit = dateSplit.split("-")[2]+"-"+dateSplit.split("-")[1]+"-"+dateSplit.split("-")[0];
					// console.log(dateSplit,"dateSplit")
					var invoice_date = moment.parseZone(new Date(dateSplit+" 00:00:00"))._d
					// console.log(invoice_date,"invoice_date")
					var currentDate = moment();
					// console.log(currentDate,"currentDate")
					dateCheck = moment(invoice_date).isSameOrBefore(moment(currentDate));
					// console.log(dateCheck,"dateCheck")
					if(dateCheck == false)
					{
						checkDate = false;
					}
				})
				// console.log(checkDate,"checkDate")
				// console.log(transitdays.length,"transitdayslength")
				// console.log(transitdayslength,"transitdayslength")
				// console.log(duplicateconsignmentcode,"duplicateconsignmentcode")
				var uniqueCheck = checkIfArrayIsUnique(duplicateconsignmentcode)
				// console.log(uniqueCheck,'uniqueCheck')
				if(duplicateconsignmentcode != "" && duplicateconsignmentcode != undefined)
				{
					if(specialCharcters == 0)
					{
						// if(uniqueCheck == true)
						// {
							if(transitdayslength == true)
							{
								if(checkDate != false)
								{
									var params = {
										csvcontent: this.state.csvcontent,
										// accessby: accessby,
										// transporter_code: transporter_stockyard_code,
									}									
									// console.log("content ", this.state.csvcontent)
									// console.log("Params ", params)
									redirectURL.post("/dashboard/bulkuploadcourierconsignmentdata", params).then((response) => {
										// console.log(response,"response stockyard")
										if (response.data.insertedCount >= 1) {
											var uploadfilebulk = $("#uploadfile").val(null)
											this.setState({
												show: true,
												basicTitle: "Records Inserted",
												basicType: "success",
												loadshow: "show-n",
												overly: "show-n",
												sliderRso: "",
												file: "",
												uploadfilebulk:uploadfilebulk
											});
											this.componentDidMount()
										}
										else if(response.data.invalidDatesCount >= 1)
										{
											this.setState({
												show: true,
												basicTitle: 'Enter Valid Date Format',
												basicType: "danger",
											});
										}
										else if(response.data.duplicateValues >= 1)
										{
											this.setState({
												show: true,
												basicTitle: 'Consignment Code Already exists',
												basicType: "danger",
											});
										}
										else {
											this.setState({
												show: true,
												basicTitle: 'Something went wrong',
												basicType: "danger",
											});
										}
										
									})
								}
								else{
									this.setState({
										show: true,
										basicTitle: 'Invoice Date should not be greater than today',
										basicType: "danger",
									});
								}
							}
							else{
								this.setState({
									show: true,
									basicTitle: 'Transit Days Should not exceed two digits',
									basicType: "danger",
								});
							}
						// } 
						// else{
						// 	this.setState({
						// 		show: true,
						// 		basicTitle: 'Consignment Code Should not be duplicate',
						// 		basicType: "danger",
						// 	});
						// }
					}
					else{
						this.setState({
							show: true,
							basicTitle: 'Special Characters are not allowed in Consignment Code',
							basicType: "danger",
						});
					}
				}
				else{
					this.setState({
						show: true,
						basicTitle: 'Consignment Code Should not be empty',
						basicType: "danger",
					});
				}
			}
				            
        }
        else{
            this.setState({
                show: true,
                basicTitle: 'Choose file to continue',
                basicType: "danger",
            });
        }
	}

	onSubmitBulkAddRemaks = (event) => {
		event.preventDefault()
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Buk Upload",
				"action": "form",
				"label": "Buk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow:'show-m',
			overly :'show-m',
		})
        console.log(this.state.csvcontentforremarks,"bulk uplosd dtaa")
        if(this.state.csvcontentforremarks != undefined && this.state.csvcontentforremarks != "")
        {
            if (this.state.csvcontentforremarks.length > 0) 
			{
				var duplicateconsignmentcode = []
				var specialCharcters = 0
				this.state.csvcontentforremarks.map((em) => {
					if(em.consignment_code != "" && em.consignment_code != undefined)
					{
						duplicateconsignmentcode.push(em.consignment_code)
					}
					
				})
				duplicateconsignmentcode.map((e) => {
					if (e.match(/[^a-zA-Z0-9]/g)) {
						specialCharcters = 1
					}
				})
				
				
				var uniqueCheck = checkIfArrayIsUnique(duplicateconsignmentcode)
				if(duplicateconsignmentcode != "" && duplicateconsignmentcode != undefined)
				{
					if(specialCharcters == 0)
					{
						// if(uniqueCheck == true)
						// {
							// if(transitdayslength == true)
							// {
							// 	// if(checkDate != false)
								// {
									var params = {
										csvcontent: this.state.csvcontentforremarks,
										uploaded_on : moment.parseZone().format('YYYY-MM-DD HH:mm:ss'),
										uploaded_by : localStorage.getItem('email')
										// transporter_code: transporter_stockyard_code,
									}
									// console.log("content ", this.state.csvcontent)
									console.log("Params ", params)
									redirectURL.post("/dashboard/bulkaddremarks", params).then((response) => {
										if(response.data.status == "success"){
											this.setState({
												show: true,
												basicTitle: 'Remarks Updated Successfully',
												basicType: "success",
												loadshow:'show-n',
												overly :'show-n',
												sliderBulkRemarks:"",
											})
										}else{
											this.setState({
												show: true,
												basicTitle: 'Remarks are not updated',
												basicType: "danger",
												loadshow:'show-n',
												overly :'show-n',
												sliderBulkRemarks:"",
											})
										}
										
									})
								}		
					else{
						this.setState({
							show: true,
							basicTitle: 'Special Characters are not allowed in Consignment Code',
							basicType: "danger",
						});
					}
				}
				else{
					this.setState({
						show: true,
						basicTitle: 'Consignment Code Should not be empty',
						basicType: "danger",
					});
				}
			}
				            
        }
        else{
            this.setState({
                show: true,
                basicTitle: 'Choose file to continue',
                basicType: "danger",
            });
        }
		
	}

	onSubmitBulkValidateRamarks = (event) => {
		event.preventDefault()
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Buk Upload",
				"action": "form",
				"label": "Buk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow:'show-m',
			overly :'show-m',
		})
        console.log(this.state.csvcontentforvalidateremarks,"bulk uplosd dtaa")
        if(this.state.csvcontentforvalidateremarks != undefined && this.state.csvcontentforvalidateremarks != "")
        {
            if (this.state.csvcontentforvalidateremarks.length > 0) 
			{
				var duplicateconsignmentcode = []
				var specialCharcters = 0
				this.state.csvcontentforvalidateremarks.map((em) => {
					if(em.consignment_code != "" && em.consignment_code != undefined)
					{
						duplicateconsignmentcode.push(em.consignment_code)
					}
					
				})
				duplicateconsignmentcode.map((e) => {
					if (e.match(/[^a-zA-Z0-9]/g)) {
						specialCharcters = 1
					}
				})
				
				
				var uniqueCheck = checkIfArrayIsUnique(duplicateconsignmentcode)
				if(duplicateconsignmentcode != "" && duplicateconsignmentcode != undefined)
				{
					if(specialCharcters == 0)
					{
						// if(uniqueCheck == true)
						// {
							// if(transitdayslength == true)
							// {
							// 	// if(checkDate != false)
								// {
									var params = {
										csvcontent: this.state.csvcontentforvalidateremarks,
										uploaded_on : moment.parseZone().format('YYYY-MM-DD HH:mm:ss'),
										// transporter_code: transporter_stockyard_code,
									}
									// console.log("content ", this.state.csvcontent)
									console.log("Params ", params)
									redirectURL.post("/dashboard/bulkaddvalidateremarks", params).then((response) => {
										if(response.data.status == "success"){
											this.setState({
												show: true,
												basicTitle: 'Remarks Validation Updated Successfully',
												basicType: "success",
												loadshow:'show-n',
												overly :'show-n',
												sliderBlukValidateRemarks:"",
											})
										}else{
											this.setState({
												show: true,
												basicTitle: 'Remarks Validation are not updated',
												basicType: "danger",
												loadshow:'show-n',
												overly :'show-n',
												sliderBlukValidateRemarks:"",
											})
										}
										
									})
								}		
					else{
						this.setState({
							show: true,
							basicTitle: 'Special Characters are not allowed in Consignment Code',
							basicType: "danger",
						});
					}
				}
				else{
					this.setState({
						show: true,
						basicTitle: 'Consignment Code Should not be empty',
						basicType: "danger",
					});
				}
			}
				            
        }
        else{
            this.setState({
                show: true,
                basicTitle: 'Choose file to continue',
                basicType: "danger",
            });
        }
		
	}


	onSubmitBulkMarkDeliverd = event => {
		event.preventDefault()
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Buk Upload",
				"action": "form",
				"label": "Buk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow:'show-m',
			overly :'show-m',
		})
		if(this.state.csvcontentformarkdelivered != undefined && this.state.csvcontentformarkdelivered != "")
        {
            if (this.state.csvcontentformarkdelivered.length > 0) 
			{
				var duplicateconsignmentcode = []
				var specialCharcters = 0
				this.state.csvcontentformarkdelivered.map((em) => {
					if(em.consignment_code != "" && em.consignment_code != undefined)
					{
						duplicateconsignmentcode.push(em.consignment_code)
					}
					
				})
				duplicateconsignmentcode.map((e) => {
					if (e.match(/[^a-zA-Z0-9]/g)) {
						specialCharcters = 1
					}
				})
				
				
				var uniqueCheck = checkIfArrayIsUnique(duplicateconsignmentcode)
				if(duplicateconsignmentcode != "" && duplicateconsignmentcode != undefined)
				{
					if(specialCharcters == 0)
					{
						// if(uniqueCheck == true)
						// {
							// if(transitdayslength == true)
							// {
							// 	// if(checkDate != false)
								// {
									var params = {
										csvcontent: this.state.csvcontentformarkdelivered,
										uploaded_on : moment.parseZone().format('YYYY-MM-DD HH:mm:ss'),
										uploaded_by : localStorage.getItem('email')
										// transporter_code: transporter_stockyard_code,
									}
									// console.log("content ", this.state.csvcontent)
									console.log("Params ", params)
									redirectURL.post("/dashboard/bulkmarkdeliverd", params).then((response) => {
										if(response.data.status == "success"){
											this.setState({
												show: true,
												basicTitle: 'Delivery Date Updated Successfully',
												basicType: "success",
												loadshow:'show-n',
												overly :'show-n',
												sliderBulkMarkDeliverd:"",
											})
										}else{
											this.setState({
												show: true,
												basicTitle: 'Delivery Date not updated',
												basicType: "danger",
												loadshow:'show-n',
												overly :'show-n',
												sliderBulksliderBulkMarkDeliverdRemarks:"",
											})
										}
										
									})
								}		
					else{
						this.setState({
							show: true,
							basicTitle: 'Special Characters are not allowed in Consignment Code',
							basicType: "danger",
						});
					}
				}
				else{
					this.setState({
						show: true,
						basicTitle: 'Consignment Code Should not be empty',
						basicType: "danger",
					});
				}
			}
				            
        }
        else{
            this.setState({
                show: true,
                basicTitle: 'Choose file to continue',
                basicType: "danger",
            });
        }
	}

	submitForm()
	{
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var fromdate = document.getElementById("from_date").value;
		var todate = document.getElementById("to_date").value;
		this.setState({
			from_date:fromdate,
			to_date:todate,
			loadshow:'show-m'
		});
		console.log("formdate",fromdate)
		console.log("todate",todate)
		if(fromdate != '')
		{
			// var fdt = fromdate.split(" ");
			var sfdate = fromdate.split("-");
			var fdate = sfdate[2]+"-"+sfdate[1]+"-"+sfdate[0];
		}
		else
		{
			fdate=""
		}
		if(todate != '')
		{

			var tdt = todate.split(" ");
			var stdate = todate.split("-");
			var tdate = stdate[2]+"-"+stdate[1]+"-"+stdate[0];
		}
		else
		{
			tdate=""
		}
		console.log("firstdate",fdate);
		console.log("TODate",tdate);
		// console.log("this.state.consigner ",this.state.consigner)
		if(this.state.consigner == null)
		{
			var consigners = [{"value":"all"}]
		}
		else{
			var consigners = this.state.consigner; 
		}
		// if(fromdate == '' && todate == '')
		// {
		// 	var reqparasm = {
		// 		from_date:fdate,
		// 		to_date:tdate,
		// 		consigner_code:[{"value":"all"}],
		// 	}
		// }
		// else
		// {
			// console.log("tdate ",tdate)
			// var consignsarr = [];
			// if(this.state.consigner.length > 0)
			// {
			// 	this.state.consigner.map((item) => {
			// 		consignsarr.push(item.value)
			// 	})
			// }
			// else{
			// 	consignsarr.push({"value":'all'})
			// }
			var serviceProviders = [];
			if(localStorage.getItem("user_type") == "TRANSPORTER")
			{
				serviceProviders = JSON.parse(localStorage.getItem('transportercode'))
			}
			if(fdate==""&&tdate!=''){
				fdate=moment(tdate).parseZone().subtract(30,'days').format('YYYY-MM-DD')
				this.setState({from_date:moment(tdate).parseZone().subtract(30,'days').format('DD-MM-YYYY')})
			}
			if(tdate==''&&fdate!=''){
				tdate = moment(fdate).parseZone().add(30,'days').format('YYYY-MM-DD')
				this.setState({to_date:moment(fdate).parseZone().add(30,'days').format('DD-MM-YYYY')})
			}
			this.setState({
				from_date:fdate,
				to_date:tdate,
				loadshow:'show-m'
			});
			var reqparasm = {
				from_date:fdate,
				to_date:tdate,
				consigner_code:consigners,
				serviceProviders : serviceProviders,
				is_filter_call : 1
			}

			if (this.state.courierConsigneeCode != "" && this.state.courierConsigneeCode != null && this.state.courierConsigneeCode != undefined) {
				reqparasm.courierConsigneeCode = this.state.courierConsigneeCode
			}
			
		//}
		console.log("reqparasm ", reqparasm)
		var urlpath;
		if(this.props.match.path == "/courierconsignments")
		{
			urlpath = '/consignments/filtercouriers';
		}
		else if(this.props.match.path == "/activecourierconsignments")
		{
			urlpath = '/consignments/getFilteredActiveCourierDataByParams';
		}
		else if(this.props.match.path == "/deliveredcourierconsignments")
		{
			urlpath = '/consignments/filterdelivercouriers';
		}

		this.onLoadShowCouriers(reqparasm)

		// redirectURL.post(urlpath,reqparasm)
		// .then((response)=>{
		// 	console.log("Resposne ", response.data)
		// 	var records = response.data;
		// 	//console.log("records", records);
		// 	var intransitarr=[];
		// 	var outdeliverarr = [];
		// 	var deliverarr = [];
		// 	var datanaarr = [];
		// 	records.map((item) => {

		// 		//In Transit
		// 		if(item.consignment_status == 'In Transit')
		// 		{
		// 			intransitarr.push(item);
		// 		}

		// 		//Out of Delivery
		// 		if(item.consignment_status == 'Out for delivery')
		// 		{
		// 			outdeliverarr.push(item);
		// 		}

		// 		//Delivered
		// 		if(item.consignment_status == 'Delivered')
		// 		{
		// 			deliverarr.push(item);
		// 		}

		// 		//Data Not Available
		// 		if(item.consignment_status == '' || item.consignment_status == null)
		// 		{
		// 			datanaarr.push(item);
		// 		}
				
				
		// 	});
		// 	let newArray = []; 
              
		// 	// Declare an empty object 
		// 	let uniqueObject = {}; 
		// 	var objTitle;
		// 	// Loop for the array elements 
		// 	for (let i in records) { 
	
		// 		// Extract the title 
		// 		objTitle = records[i]['consigner_code']; 
	
		// 		// Use the title as the index 
		// 		uniqueObject[objTitle] = records[i]; 
		// 	} 
			
		// 	// Loop to push unique object into array 
		// 	for (var a in uniqueObject) { 
		// 		newArray.push(uniqueObject[a]); 
		// 	} 
			
		// 	//console.log("newArray ", newArray)
		// 	var consigners=[];
		// 	newArray.map((ccode) => {
		// 		consigners.push(ccode.consigner_code)
		// 	})
        //     this.setState({
		// 		consigners:consigners,
		// 		rowData:records,
		// 		intransit:intransitarr,
		// 		outdeliver:outdeliverarr,
		// 		deliver:deliverarr,
		// 		datana:datanaarr,
		// 		intransitcount:intransitarr.length,
		// 		outdelivercount:outdeliverarr.length,
		// 		delivercount:deliverarr.length,
		// 		datanacount:datanaarr.length,
		// 		totalcount:records.length,
		// 		loadshow:'show-n'
        //     });
		// })
		// .catch(function(e){
		// 	console.log("Error ", e)
		// })
	}

	//Map Code by Govind
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			sliderTranslatesidebar: "",
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			loadshow: 'show-n',
			slideuploadeway: ""
		});
	}

	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			sliderCommentTranslate: "",
			sliderSegmentTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			devation_flag: false
		});
	}
	//Map Code by Govind

	onShowRouteDiv = (e) => {
		var coord = []
		console.log("3721",e.data);
		console.log("3722",e.data.consignment_status)
		var {dispatch_date,revised_eta,consigner_code,service_provider} = e.data;
		if(e.data.consignment_status != "Invalid docket"){
			if(dispatch_date.includes("T")){
				var check_date1 = dispatch_date.split("T")
				var check_date1_date = check_date1[0]
				var check_date1_time = check_date1[1].split(".")[0]
				var dispatch_date1 = check_date1_date +" "+check_date1_time
			}else{
				var dispatch_date1 = "-"
			}
			if(revised_eta.includes("T")){
				var check_date2 = revised_eta.split("T")
				var check_date2_date = check_date2[0]
				var check_date2_time = check_date2[1].split(".")[0]
				var revised_eta1 = check_date2_date +" "+check_date2_time
			}else{
				var revised_eta1 = "-"
			}
			var startDate = moment.parseZone(new Date(dispatch_date1)).format("DD/MM/YYYY HH:mm");
			var endDate = moment.parseZone(new Date(revised_eta1)).format("DD/MM/YYYY HH:mm");
			// console.log(endDate,"3725")
			var consignee_code = e.data.consignee_code;
			//To include Starting point i.e plant coords
			if(this.state.allPlantsCoords.hasOwnProperty(e.data.consigner_code)){
				var plantCoords = JSON.parse(this.state.allPlantsCoords[e.data.consigner_code])
				coord.push(plantCoords)
			}
			console.log("coords after pushing plant",coord);
			if (googleAnalytics.page.enableGA) {
				let eventOptions = {
					"category": this.state.pagetitle,
					"action": this.state.eventGridAction,
					"label": googleAnalytics.page.action.viewRoute,
				}
				googleAnalytics.logEvent(eventOptions);
			}
	
			// console.log("IN onShowRouteDiv, Rute params ", e)
			// this.setState({
			// 	loadshow: 'show-m',
			// });
			this.setState({
				sliderRouteTranslate:"slider-translate-60p",
				consignment_code:"Invoice No: " + e.data.consignment_code,
				serviceProvider:service_provider.toLocaleUpperCase(),
				startDate:startDate,
				endDate:endDate
			})
	
			redirectURL.post('/consignments/trackcourier', {
				consignment_code:e.data.consignment_code
			}).then(async (resp)=>{
				// console.log("consignments/trackcourier",resp.data);
				// location_lat  location_lng
				var coordinates = await resp.data;
				for(let each of coordinates){
					var {location_lat,location_lng} = each;
					var checkArr = ["",null,undefined,0,"0"]
					if(!checkArr.includes(location_lat) && !checkArr.includes(location_lng)){
						var eachCoord = {
							lat:parseFloat(location_lat),
							lng:parseFloat(location_lng)
						}
						coord.push(eachCoord)
					}
				}
				//To include dealer coords if exists else last location will be given
				if(this.state.dealerCoords.hasOwnProperty(consignee_code)){
					var dealerCoord = this.state.dealerCoords[consignee_code];
					coord.push(dealerCoord)
				}
				// console.log("consignee_code",consignee_code);
				// console.log("coords after pushing dealer",this.state.dealerCoords);
				var plantName = ""
				if(this.state.allPlantAddresses.hasOwnProperty(consigner_code)){
					plantName = this.state.allPlantAddresses[consigner_code]
				}
				// console.log();
				var dealerCity = ""
				if(this.state.dealerCities.hasOwnProperty(consignee_code)){
					dealerCity = this.state.dealerCities[consignee_code]
				}
				// console.log("dealerCities",this.state.dealerCities);
	
				var routeDetails = {
					start_time : startDate,
					end_time : endDate,
					plant_name : plantName,
					legs : coordinates,
					dealerCity:dealerCity
				 }
				// this.state.mapinfo
				var consignmentData = {
					coords:coord,
					breaks:[],
					route_details:routeDetails
				}
				this.setState({
					// coordArr:coord
					mapinfo:consignmentData
				})
				this.renderMap();
				// console.log("dealerCoord",dealerCoord);
			}).catch(err => {
				console.log(err,"err123")
			})
		}else{
			this.setState({
				basicTitle:"No Route Available",
				basicType:"danger",
				show:true
			})
		}
		
	}
	getTruckConsignments=(event)=>{
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Search for truck/consignment",
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var selectedTruckConsignmentsFilter = $("#selectedTruckConsignmentsFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		console.log(selectedTruckConsignmentsFilter,"inputtruckconsign")
		if(inputTruckConsignment == "" || selectedTruckConsignmentsFilter=="Select...")
		{
			this.setState({
				basicTitle : "Fields Should not be Empty",
				basicType : "danger",
				show : true
			})
		}
		else
		{
			console.log(selectedTruckConsignmentsFilter,inputTruckConsignment);
			var urlpath=""
			if(this.props.match.path == "/activecourierconsignments")
			{
				urlpath = "/consignments/filterActiveCourierByConsignment";
				var pageurl = "/activecourierconsignments";
			}
			if(this.props.match.path == "/deliveredcourierconsignments")
			{
				urlpath = "/consignments/filterActiveCourierByConsignment";
				var pageurl = "/deliveredcourierconsignments";
			}
			if(this.props.match.path == "/courierconsignments")
			{
				urlpath = "/consignments/filterActiveCourierByConsignment";
				var pageurl = "/courierconsignments";
			}
			if(this.props.match.path == "/invalidcourierconsignments")
			{
				urlpath = "/consignments/filterActiveCourierByConsignment";
				var pageurl = "/invalidcourierconsignments";
			}

			var params = {
				"selectedTruckConsignmentsFilter": selectedTruckConsignmentsFilter,
				"inputTruckConsignment": inputTruckConsignment,
				"urlpath" : pageurl
			}

			if (this.state.courierConsigneeCode != "" && this.state.courierConsigneeCode != null && this.state.courierConsigneeCode != undefined) {
				params.courierConsigneeCode = this.state.courierConsigneeCode
			}

			redirectURL.post(urlpath, params).then((response)=>{
				var records = response.data;
				var intransitarr=[];
				var priorityarr=[];
				var outdeliverarr = [];
				var deliverarr = [];
				var forcearr = [];
				var datanaarr = [];
				var confirmDelivery=[]
				records.map((item) => {
					
					//In Transit
					if(item.consignment_status == 'In Transit')
					{
						intransitarr.push(item);
					}

					//Out of Delivery
					if(item.consignment_status == 'Out For Delivery')
					{
						outdeliverarr.push(item);
					}

					//Delivered
					if(item.consignment_status == 'Delivered')
					{
						deliverarr.push(item);
					}

					if(item.consignment_status == 'Force Closed')
					{
						forcearr.push(item);
					}
					//Data Not Available
					if(item.consignment_status == '' || item.consignment_status == null || item.consignment_status == "Invalid docket")
					{
						// Change later
						// datanaarr.push(item);
					}
					if(item.tpt_confirm_delivery == 1)
					{
						confirmDelivery.push(item);
					}
					//Priority
					if(item.is_priority == 1)
					{
						priorityarr.push(item)
					}
					
				});
				var on_time_data =[]
					if(this.props.match.path == "/deliveredcourierconsignments")
					{
						on_time_data = records.filter(e => {
							if(e.delivery_datetime != "" && e.delivery_datetime != undefined && e.expected_eta != "" && e.expected_eta != undefined)
							{
								if (new Date(e.delivery_datetime) <= new Date(e.expected_eta)) {
									return e
								}
							}
						})
					}
					var delayed_data = []
					if(this.props.match.path == "/deliveredcourierconsignments")
					{
						
							delayed_data = records.filter(e => {
								if(e.delivery_datetime!="" && e.delivery_datetime !=undefined  && e.expected_eta != "" && e.expected_eta != undefined)
								{
									if (new Date(e.delivery_datetime) > new Date(e.expected_eta)) {
										return e
									}
								}
							})
					}
				let newArray = []; 
				// console.log("item.consignment_status",outdeliverarr)
				// Declare an empty object 
				let uniqueObject = {}; 
				var objTitle;
				// Loop for the array elements 
				for (let i in records) { 
		
					// Extract the title 
					objTitle = records[i]['consigner_code']; 
		
					// Use the title as the index 
					uniqueObject[objTitle] = records[i]; 
				} 
				
				// Loop to push unique object into array 
				for (var a in uniqueObject) { 
					newArray.push(uniqueObject[a]); 
				} 
				
				//console.log("newArray ", newArray)
				var consigners=[];
				newArray.map((ccode) => {
					consigners.push(ccode.consigner_code)
				})
				//console.log("records ", records)
				if(this.props.match.path == "/invalidcourierconsignments")
				{
					var allrecords = datanaarr;
				}
				else
				{
					var allrecords = records;
				}
				// allrecords = allrecords.filter(e => e.service_provider != 'spoton logistics pvt ltd')
				// allrecords = allrecords.filter(e => e.consignment_status != "Invalid docket")
				allrecords = allrecords.filter(e => e.hasOwnProperty("consignment_status"))
				this.setState({
					rowData:allrecords,
					originaldata:allrecords,
					intransit:intransitarr,
					outdeliver:outdeliverarr,
					deliver:deliverarr,
					datana:datanaarr,
					prioritydata:priorityarr,
					intransitcount:intransitarr.length,
					outdelivercount:outdeliverarr.length,
					delivercount:deliverarr.length,
					datanacount:datanaarr.length,
					totalcount:allrecords.length,
					prioritydatacount:priorityarr.length,
					confirmDelivery:confirmDelivery,
					on_time_data:on_time_data,
					delayed_data:delayed_data,
					on_time_data_count:on_time_data.length,
					delayed_data_count:delayed_data.length,
					forceclosecount:forcearr,
					force_close_count:forcearr.length,
					loadshow:'show-n'
				});
				// this.setState({
				// 	rowData : response.data,
				// })
			}).catch=((e)=>
			{
				console.log(e);
			})
		}
	}

	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = '';

		if(this.props.match.path == "/courierconsignments")
		{
			screenpage='transporter courier consignments (all)';
		}
		else if(this.props.match.path == "/activecourierconsignments")
		{
			screenpage='transporter courier consignments (active)';
		}
		else if(this.props.match.path == "/deliveredcourierconsignments")
		{
			screenpage='transporter courier consignments (delivered)';
		}
		else if(this.props.match.path == "/invalidcourierconsignments")
		{
			screenpage='transporter courier consignments (invalid)';
		}

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		try{


			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};
	onCellClicked(e){
		if(e.colDef.field == "priority")
		{
			if(e.data.is_priority !=1)
			{
				this.setState({
					loadshow : "show-m",
					overly:"show-m",
				});
				var consignmentId = e.data.consignment_code;
				var email = localStorage.getItem("email");
				var reqParams = {
					consignmentId : consignmentId,
					email : email
				}
				redirectURL.post("/consignments/updateCourierPriority",reqParams).then((response)=>{
					this.setState({
						loadshow : "show-n",
						overly:"show-n",
						basicPriorityTitle : "Consignment Marked As Priority",
						basicPriorityType : "success",
						showPriority : true,
					});
				}).catch((e)=>{
					console.log(e)
				})
			}
		}
	}
	render(){
		var hideonlyacive = (this.props.match.path == "/activecourierconsignments")?true:false;
		var hideForDealer = (localStorage.getItem('user_type') == 'COURIER_CONSIGNEE') ? true : false;
		let hideCouriersCheckBox = ((this.props.match.path == "/activecourierconsignments") 
		&& (localStorage.getItem("user_type") == "TRANSPORTER"))?false:true;
		//console.log("Rendere ",this.state.deptcode)
		if(this.state.counttype == "active")
		{
			var hidden = false
		}
		else{
			var hidden = true
		}
		if(this.state.counttype != "active" && this.state.counttype != "invalid")
		{
			var reasonHide = false; 
		}
		else{
			var reasonHide = true;
		}
		if(localStorage.getItem("user_type") == "TRANSPORTER")
		{
			hidden = true;
			reasonHide = true;
		}
		const hideCritical = ((this.state.counttype == "active") || (this.state.counttype == "all"))?false:true;
		
		const columnwithDefs = [
                {
                    headerName: "",
                    field: "_id",
                    width: 70,
					pinned:'left',
                    cellRenderer:'courierActions',
                    filter: false,
                },
				{
                    headerName: "View Route",
                    field: "view_route",
					width: 80,
					pinned: 'left',
                    cellRenderer:'courierConsignmentActions',                    
                    filter: false,
                },
				{
					headerName: "Consignment Code",
					field: "consignment_code",
					width: 115,
					editable:false,
					pinned:'left',
					filter: "agSetColumnFilter",
					//cellRenderer: "agGroupCellRenderer"
				},
				{
					headerName: "Invoice No",
					field: "invoice_no",
					width: 115,
					editable:false,
					pinned:'left',
					filter: "agSetColumnFilter",
					//cellRenderer: "agGroupCellRenderer"
				},
				// {
				// 	headerName: "MGPA No",
				// 	field: "mgpa_no",
				// 	width: 115,
				// 	editable:false,
				// 	pinned:'left',
				// 	filter: "agSetColumnFilter",
				// 	//cellRenderer: "agGroupCellRenderer"
				// },
				{
                    headerName: "Invoice Date",
                    field: "mgpa_date_time",
                    width: 120,                 
                    // filter: "agSetColumnFilter",
					cellRenderer:'',
					pinned:'left',
					sort:"desc",
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.mgpa_date_time)
					},
					//filter: "agDateColumnFilter",
						comparator: dateComparator1,          
				},
				{
                    headerName: "Dispatch Date",
                    field: "dispatch_date",
                    width: 120,                 
                    // filter: "agSetColumnFilter",
					cellRenderer:'',
					pinned:'left',
					sort:"desc",
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.dispatch_date)
					},
					//filter: "agDateColumnFilter",
						comparator: dateComparator1,          
				},
                {
                    headerName: "Status",
                    field: "consignment_status",                    
                    width: 120,
					pinned:'left',
                    filter: "agSetColumnFilter",
                    cellStyle: function(params) {
                        if (params.value=='Delivered') {
                            //mark police cells as red
                            return {color: 'green'};
                        } else {
                            return null;
                        }
					},
					valueGetter: function(params)
					{
						if(params.data.consignment_status == "Cancelled")
						{
							return "Cancelled As Per VE"
						}
						else
						{
							return params.data.consignment_status
						}
					}
				  },
				   
				  {
					  headerName: "Expected Date of Delivery",
					  field: "expected_eta",
					  width: 120,   
					  pinned: 'left',              
					  resizable: true,
					  valueGetter:function(params){
						  return getHyphenDDMMMYYYYHHMM(params.data.expected_eta);	 
					  },
					  //filter: "agDateColumnFilter",
					  comparator: dateComparator,
					//   filterParams: {
					// 	  browserDatePicker: true,
					// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	  // provide comparator function
					// 	  comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		  if(cellValue != "" && cellValue != " "){
					// 			  cellValue = cellValue.split(" ")[0].split("-");
					// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			  var cellDate = new Date(cellValue);
					// 			  if(filteredDate.getMonth() < 10){
					// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  else{
					// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  if(filterValue.split("-")[2].length < 2)
					// 			  {
					// 				  var temp = filterValue
					// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			  }
					// 			  var filteredDate = new Date(filterValue);
					// 			  cellDate = cellDate.getTime();
					// 			  filteredDate = filteredDate.getTime();
					// 			  // console.log(cellDate,filteredDate);
					// 			  if(cellDate === filteredDate)
					// 			  {
					// 				  return 0;
					// 			  }
					// 			  if(cellDate < filteredDate){
					// 				  return -1;
					// 			  }
					// 			  if(cellDate > filteredDate)
					// 			  {
					// 				  return 1;
					// 			  }
					// 		  }
					// 	  }
					//   }, 	          
				  },          
				  {
					  headerName: "Revised ETA",
					  field: "revised_eta",
					  width: 120,   
					  pinned: 'left',              
					  resizable: true,
					  valueGetter:function(params){
						  //console.log(" ETA ", params.data)
							if(params.data.revised_eta == '' || params.data.revised_eta == undefined)
							{
								return getHyphenDDMMMYYYYHHMM(params.data.revised_eta);
							}
							else{
								return getHyphenDDMMMYYYYHHMM(params.data.revised_eta);
							}
					  
					  },
					  //filter: "agDateColumnFilter",
					  comparator: dateComparator,
					//   filterParams: {
					// 	  browserDatePicker: true,
					// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	  // provide comparator function
					// 	  comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		  if(cellValue != "" && cellValue != " "){
					// 			  cellValue = cellValue.split(" ")[0].split("-");
					// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			  var cellDate = new Date(cellValue);
					// 			  if(filteredDate.getMonth() < 10){
					// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  else{
					// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  if(filterValue.split("-")[2].length < 2)
					// 			  {
					// 				  var temp = filterValue
					// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			  }
					// 			  var filteredDate = new Date(filterValue);
					// 			  cellDate = cellDate.getTime();
					// 			  filteredDate = filteredDate.getTime();
					// 			  // console.log(cellDate,filteredDate);
					// 			  if(cellDate === filteredDate)
					// 			  {
					// 				  return 0;
					// 			  }
					// 			  if(cellDate < filteredDate){
					// 				  return -1;
					// 			  }
					// 			  if(cellDate > filteredDate)
					// 			  {
					// 				  return 1;
					// 			  }
					// 		  }
					// 	  }
					//   },
					  cellClass:function(params){
						  let hourDifference = 0;
						  if (params.data.revised_eta && params.data.expected_eta) {
							  let revisedDateOnlyMoment  = moment(params.data.revised_eta);
							  let expectedDateOnlyMoment  = moment(params.data.expected_eta);
							  hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
							  //console.log("diff= ", params.data.consignment_code, hourDifference);
						  } else {
							  //console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
						  }
  
						  if(hourDifference >= 1)
						  {
							  return 'bgColorDangerMedium'
						  }
  
						  if(hourDifference <= -1)
						  {
							  return 'bgColorSuccessMedium'
						  }
						  else{
							  return ''
						  }
					  }
				  },
				  {
					headerName: "Delivered?",
					field: "",
					width: 120,
					pinned : "left",
					//checkboxSelection: true,
					hide:hideCouriersCheckBox,
                    cellRendererSelector:function(params){
                        
                            var rendComponent = {
                                component: 'deliverconsignment'
                            };
                            return rendComponent
						
					},
                    // valueGetter:function(params){
                    //     if(params.data.tpt_confirm_delivery == 1)
                    //     {
                    //         //console.log("tpt_delivery_date ",params.data.tpt_delivery_date);
                    //         return getHyphenDDMMMYYYYHHMM(params.data.tpt_delivery_date)
                    //     }
                    //     else{
                    //         return '';
                    //     }
                    // },
                    filter: false,
                    resizable: true
					
				},
				  {
                    headerName: "Transit Delays (Days)",
                    field: "",                    
                    width: 130,
                    valueGetter : function(params){
						var revisedeta = getHyphenYYYYMMDD(params.data.revised_eta);
						// console.log(revisedeta,"revisedeta")
						var originaleta = getHyphenYYYYMMDD(params.data.expected_eta);
						if(originaleta != "" && originaleta != null && originaleta != NaN && revisedeta != "" && revisedeta != null && revisedeta != NaN)
						{
							var diff = differenceInDays(revisedeta,originaleta);
							// console.log(diff,"diff")
							if(diff > 0)
							{
								return diff;
							}
							else{
								return 0;
							}
						}
						else{
							return "";
						}
					}
				  },
				  {
					headerName: "Service Provider",
					field: "service_provider",				
					width: 135,
					filter: "agSetColumnFilter",
					valueGetter:function(params){
						return params.data.service_provider;
						},         
					},
				  {
					headerName: "Transit Time/VE (Days)",
					field: "expected_transit_days",
					width:150,
					filter: true,
					resizable: true,
					editable:false
				},
				  {
					headerName: "Delivered On",
					field: "delivery_datetime",
					hide:hideonlyacive,
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer:'',
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.delivery_datetime)
					},
					//filter: "agDateColumnFilter",
					  comparator: dateComparator,
					//   filterParams: {
					// 	  browserDatePicker: true,
					// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	  // provide comparator function
					// 	  comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		  if(cellValue != "" && cellValue != " "){
					// 			  cellValue = cellValue.split(" ")[0].split("-");
					// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			  var cellDate = new Date(cellValue);
					// 			  if(filteredDate.getMonth() < 10){
					// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  else{
					// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  if(filterValue.split("-")[2].length < 2)
					// 			  {
					// 				  var temp = filterValue;
					// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			  }
					// 			  var filteredDate = new Date(filterValue);
					// 			  cellDate = cellDate.getTime();
					// 			  filteredDate = filteredDate.getTime();
					// 			  // console.log(cellDate,filteredDate);
					// 			  if(cellDate === filteredDate)
					// 			  {
					// 				  return 0;
					// 			  }
					// 			  if(cellDate < filteredDate){
					// 				  return -1;
					// 			  }
					// 			  if(cellDate > filteredDate)
					// 			  {
					// 				  return 1;
					// 			  }
					// 		  }
					// 	  }
					//   },
				
				  },
				  {
                    headerName: "Delivery in Transit (Days)",
                    field: "",                    
                    width: 150,
					hide: hideForDealer,
                    valueGetter : function(params){
						var delivery_datetime = params.data.delivery_datetime;
						var mgpa_date_time = params.data.mgpa_date_time;
						if(delivery_datetime == "" || delivery_datetime == undefined || delivery_datetime == " " || delivery_datetime == NaN || delivery_datetime == "Invalid date"){
							delivery_datetime = new Date();
						}
						if(mgpa_date_time != "" && mgpa_date_time != null && mgpa_date_time != NaN)
						{
							var diff = deliveryInTransit(delivery_datetime,mgpa_date_time);
							if (diff > 0){
							return diff - 1;
							}
							else{
								return 0;
							}
						}
						else{
							return "";
						}
					}
				  },
				//   {
                //     headerName: "In Transit Delay (Days)",
                //     field: "",                    
                //     width: 150,
                //     valueGetter : function(params){
				// 		var currentDate = new Date();
				// 		var mgpa_date_time = params.data.mgpa_date_time;
				// 		if(mgpa_date_time != "" && mgpa_date_time != null && mgpa_date_time != NaN)
				// 		{
				// 			var diff = InTransitDelay(currentDate,mgpa_date_time);
				// 			return diff;
				// 		}
				// 		else{
				// 			return "";
				// 		}
				// 	}
				//   }, 
				{
                    headerName: "Order Type",
                    field: "order_type",                    
                    width: 150,
					filter: "agSetColumnFilter",
					valueGetter:function(params){
						// console.log(params,"params")
						if(params.data.order_type != "" & params.data.order_type != undefined)
						{
							return params.data.order_type;
						}
						else{
							return ""
						}
					}
				  }, 
				  {
                    headerName: "Courier Status",
                    field: "last_status",                    
                    width: 150,
					filter: "agSetColumnFilter",
					// valueGetter:function(params){
					// 	if(typeof params.data.track_last_status != 'undefined')
					// 	{
					// 		if(params.data.track_last_status.length > 0)
					// 		{
					// 			var last_track_status = params.data.track_last_status[0].transit_details.sort(GetSortDescOrder("intransit_date"))
					// 			last_track_status = last_track_status.sort(GetSortDescOrder("intransit_time"))
					// 			console.log("Last ",last_track_status)
					// 		}
					// 	}
					// }
				  },  
				  {
                    headerName: "Courier Location",
                    field: "last_location",                    
                    width: 150,
                    filter: "agSetColumnFilter"
				  },  

				  {
                    headerName: "Updated On",
                    field: "last_date",                    
                    width: 150,
					// filter: "agSetColumnFilter",
					valueGetter : function(params){
						if(params.data.last_date != "" && params.data.last_date){
							return getHyphenDDMMMYYYYHHMM(params.data.last_date);
						}
					},
					//filter: "agDateColumnFilter",
					  comparator: dateComparator,
					//   filterParams: {
					// 	  browserDatePicker: true,
					// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	  // provide comparator function
					// 	  comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		  if(cellValue != "" && cellValue != " "){
					// 			  cellValue = cellValue.split(" ")[0].split("-");
					// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			  var cellDate = new Date(cellValue);
					// 			  if(filteredDate.getMonth() < 10){
					// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  else{
					// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  if(filterValue.split("-")[2].length < 2)
					// 			  {
					// 				  var temp = filterValue;
					// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			  }
					// 			  var filteredDate = new Date(filterValue);
					// 			  cellDate = cellDate.getTime();
					// 			  filteredDate = filteredDate.getTime();
					// 			  // console.log(cellDate,filteredDate);
					// 			  if(cellDate === filteredDate)
					// 			  {
					// 				  return 0;
					// 			  }
					// 			  if(cellDate < filteredDate){
					// 				  return -1;
					// 			  }
					// 			  if(cellDate > filteredDate)
					// 			  {
					// 				  return 1;
					// 			  }
					// 		  }
					// 	  }
					//   },
				  }, 
				  {
                    headerName: "Last Processed On",
                    field: "last_process_on",                    
                    width: 150,
					// filter: "agSetColumnFilter",
					valueGetter : function(params){
						if(params.data.last_process_on != "" && params.data.last_date){
							return getHyphenDDMMMYYYYHHMM(params.data.last_process_on);
						}
					},
					//filter: "agDateColumnFilter",
					  comparator: dateComparator,
					//   filterParams: {
					// 	  browserDatePicker: true,
					// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	  // provide comparator function
					// 	  comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		  if(cellValue != "" && cellValue != " "){
					// 			  cellValue = cellValue.split(" ")[0].split("-");
					// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			  var cellDate = new Date(cellValue);
					// 			  if(filteredDate.getMonth() < 10){
					// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  else{
					// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  if(filterValue.split("-")[2].length < 2)
					// 			  {
					// 				  var temp = filterValue;
					// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			  }
					// 			  var filteredDate = new Date(filterValue);
					// 			  cellDate = cellDate.getTime();
					// 			  filteredDate = filteredDate.getTime();
					// 			  // console.log(cellDate,filteredDate);
					// 			  if(cellDate === filteredDate)
					// 			  {
					// 				  return 0;
					// 			  }
					// 			  if(cellDate < filteredDate){
					// 				  return -1;
					// 			  }
					// 			  if(cellDate > filteredDate)
					// 			  {
					// 				  return 1;
					// 			  }
					// 		  }
					// 	  }
					//   },
				  },  
				  {
                    headerName: "Consigner Code",
                    field: "consigner_code",
                    width: 130,
                    filter: "agSetColumnFilter",
					cellRenderer:''	    	          
                },               
                {
                  headerName: "Consignee Code",
                  field: "consignee_code",
                  width: 130,                 
                  filter: "agSetColumnFilter",
				  cellRenderer:''	    	          
				}, 
				{
					headerName: "Consignee Name",
					field: "consignee_name",
					width: 130,                 
					filter: "agSetColumnFilter",
					resizable:true	    	          
				}, 
				{
                    headerName: "Consignee City",
                    field: "consignee_city",
                    width: 130,
                    filter: "agSetColumnFilter",
					cellRenderer:''	    	          
                },    
	  	          
				{
                    headerName: "Consignee State",
                    field: "consignee_state",
                    width: 130,
                    filter: "agSetColumnFilter",
					cellRenderer:''	    	          
				}, 
				{
                    headerName: "Vehicle Mode",
                    field: "vehicle_mode",
                    width: 130,
                    filter: "agSetColumnFilter",
					resizable:true,    	          
                },  
                {
                headerName: "Delivered At",
                field: "delivery_station",
                hide:hideonlyacive,
                width: 150,
                filter: "agSetColumnFilter",
                cellRenderer:''	          
              },
	  	        // {
		        //   headerName: "Service Provider Code",
                //   field: "transporter_code",
                //   width: 130,
                //   filter: "agSetColumnFilter",
				//   cellRenderer:''	          
                // }, 
				// {
				// headerName: "Service Provider Name",
				// field: "service_provider",				
				// width: 135,
				// filter: "agSetColumnFilter",
				// valueGetter:function(params){
				// 	return params.data.service_provider;
				// 	},         
				// },
				// {
                //     headerName: "VE POD Date",
                //     field:"pod_date",
                //     width:110,
				// 	valueGetter:(params)=>{
				// 		if(params.data.pod_date != undefined && params.data.pod_date != null)
				// 		{
				// 			return getHyphenDDMMMYYYYHHMM(params.data.pod_date)
				// 		}
				// 		else{
				// 			return ""
				// 		}
				// 	},
				// 	resizable: true,

				// },
				// {
				// 	headerName: "TPT Delivery Date",
				// 	field: "tpt_delivery_date",				
				// 	width: 135,
				// 	filter: "agSetColumnFilter",
				// 	valueGetter:function(params){
				// 		if(params.data.tpt_delivery_date != "")
				// 		{
				// 			return getHyphenDDMMMYYYYHHMM(params.data.tpt_delivery_date);
				// 		}
				// 		else
				// 		{
				// 			return ""
				// 		}
						
				// 	}, 
				// 	//filter: "agDateColumnFilter",
				// 	  comparator: dateComparator,
				// 	//   filterParams: {
				// 	// 	  browserDatePicker: true,
				// 	// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// 	  // provide comparator function
				// 	// 	  comparator: function(filteredDate,cellValue,secondDate) {
				// 	// 		cellValue = cellValue.replace(/\//g,"-")
				// 	// 		  if(cellValue != "" && cellValue != " "){
				// 	// 			  cellValue = cellValue.split(" ")[0].split("-");
				// 	// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 	// 			  var cellDate = new Date(cellValue);
				// 	// 			  if(filteredDate.getMonth() < 10){
				// 	// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 	// 			  }
				// 	// 			  else{
				// 	// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 	// 			  }
				// 	// 			  if(filterValue.split("-")[2].length < 2)
				// 	// 			  {
				// 	// 				  var temp = filterValue;
				// 	// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 	// 			  }
				// 	// 			  var filteredDate = new Date(filterValue);
				// 	// 			  cellDate = cellDate.getTime();
				// 	// 			  filteredDate = filteredDate.getTime();
				// 	// 			  // console.log(cellDate,filteredDate);
				// 	// 			  if(cellDate === filteredDate)
				// 	// 			  {
				// 	// 				  return 0;
				// 	// 			  }
				// 	// 			  if(cellDate < filteredDate){
				// 	// 				  return -1;
				// 	// 			  }
				// 	// 			  if(cellDate > filteredDate)
				// 	// 			  {
				// 	// 				  return 1;
				// 	// 			  }
				// 	// 		  }
				// 	// 	  }
				// 	//   },        
				// },
				// {
				// 	headerName: "TPT Delivery Reason",
				// 	field: "tpt_delivery_reason",				
				// 	width: 200,
				// 	filter: "agSetColumnFilter",   
				// },
				// {
				// 	headerName : "Critical",
				// 	field : "critical_record",
				// 	width: 120,
				// 	hide : hideCritical,
				// 	valueGetter : function(params)
				// 	{
				// 		if(params.data.critical_record == 1)
				// 		{
				// 			return "Yes";
				// 		}
				// 		else
				// 		{
				// 			return "No";
				// 		}
				// 	}
				// }, 
				{
					headerName : "Priority",
					field : "is_priority",
					width: 120,
					//hide : hidden,
					valueGetter : function(params)
					{
						if(params.data.is_priority == 1)
						{
							return "Yes";
						}
						else
						{
							return "No";
						}
					}
				},
				// {
				// 	headerName: "Mark Priority",
				// 	field: "priority",
				// 	width: 130,
				// 	cellRenderer:function(params){
				// 		if(params.data.is_priority == 1)
				// 		{
				// 			return "";
				// 		}
				// 		else
				// 		{
				// 			var htmloption = '<button class="btn btn-info" style="padding-top:0px">Priority</button>';
                // 			return htmloption;
				// 		}
				// 	},
				// 	//hide : hidden,
				// 	filter: false,
				// 	resizable: true,	
				// },
				// {
				// 	headerName: "Force Close",
				// 	field: "consignment_code",
				// 	width: 80,
				// 	cellRendererSelector:function(params){					
				// 		var rendComponent = {
				// 			component: 'consignmentforceclose'
				// 		};
				// 		return rendComponent;
				// 	},
				// 	hide : hidden,
				// 	filter: false,resizable: true,	
				// }, 

				
				{
					headerName: "Mark Delivered",
					field: "consignment_code",
					width: 100,
					cellRendererSelector:function(params){					
						var rendComponent = {
							component: 'MarkDeliver'
						};
						return rendComponent;
					},
					hide : hidden,
					filter: false,resizable: true,	
				},
				// {
				// 	headerName: "Force Close Reason",
				// 	field: "force_close_reason",
				// 	width: 150,
				// 	hide : reasonHide,
				// 	filter: false,resizable: true,	
				// },  
				             
	  	        
                // {
                //     headerName: "Vehicle Mode",
                //     field: "vehicle_mode",
                //     width: 180,                 
                //     filter: "agSetColumnFilter",
				// 	cellRenderer:''	    	          
                // },
                // {
                //     headerName:"Route ID",
                //     field:"route_id",
                //     width:200,
                //     filter:"agSetColumnFilter",
				// 	cellRenderer:''
                // },
                
                
                // {
                //     headerName:"Items",
                //     field:"items",
                //     width:200,
                //     filter:"agSetColumnFilter",
				// 	cellRenderer:''
                // },            
                // {
                //   headerName: "Driver1 Code",
                //   field: "driver1_code",
                //   width: 120,                 
                //   filter: "agSetColumnFilter",
				//   cellRenderer:''    	          
                // },
                // {
                //     headerName: "Driver2 Code",
                //     field: "driver2_code",
                //     width: 180,                 
                //     filter: "agSetColumnFilter",
				// 	cellRenderer:''	    	          
				// },
				// {
                //     headerName: "Cluster",
                //     field: "cluster",
                //     width: 180,                 
                //     filter: "agSetColumnFilter",
				// 	cellRenderer:''	    	          
				// },
								
            			
                
		];

		var showRemarksColumn = this.state.showRemarksButton
		if(this.props.match.path == "/deliveredcourierconsignments")
		{
			columnwithDefs.push(
				{
					headerName: "Force Close Reason",
					field: "force_close_reason",
					width: 150,
					hide : reasonHide,
					filter: false,resizable: true,	
				})
			if(showRemarksColumn == true){
				columnwithDefs.push(	{
					headerName:"Remarks",
					field:"courier_dealay_remarks",
					width:200,
					resizable: true,	
				},
				{
					headerName: "Update Remarks",
					field: "remarks",
					width:100,
					cellRenderer:'CourierDelayedRemarks',
					filter: false,
					resizable: true,	

				},)
			}
				       
		}
		if(this.props.match.path != "/deliveredcourierconsignments")
		{
			columnwithDefs.push({
				headerName: "Mark Priority",
				field: "priority",
				width: 130,
				cellRenderer:function(params){
					if(params.data.is_priority == 1)
					{
						return "";
					}
					else
					{
						var htmloption = '<button class="btn btn-info" style="padding-top:0px">Priority</button>';
						return htmloption;
					}
				},
				//hide : hidden,
				filter: false,
				resizable: true,	
			})
		}
		if(this.props.match.path == "/courierconsignments" || this.props.match.path == "/activecourierconsignments")
		{
			columnwithDefs.push({
				headerName: "Force Close",
				field: "",
				width: 80,
				cellRendererSelector:function(params){				
					if(params.data.consignment_status != "Delivered" && params.data.consignment_status != "Force Closed")
					{
						var rendComponent = {
							component: 'consignmentforceclose'
						};
						return rendComponent;
					}	
					else{
						return ""
					}
				},
				hide : hidden,
				filter: false,resizable: true,	
			})
		}
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,

	}
	const {delivermodal} = this.state;
	const modalStyles  = {
		width:'1300px !important',
	}
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
				>
				</SweetAlert>
				<SweetAlert
	            show={this.state.showPriority}
	            type={this.state.basicPriorityType}
	            title={this.state.basicPriorityTitle}
	            onConfirm={this.closePriorityAlert}
				>
				</SweetAlert>
	            {this.state.alert}
				<div className="row">
					<div style={{width:'100%'}}>
							<h5 style={{float:"left"}}>
										<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle}</span>
							</h5>
					</div>
				{(this.props.match.path !="/billingreportcourierconsignments" )?
				<div className="col-xl-5 col-lg-6">
					
				<div className=" card" >
						
					<div className="row card-body"style={{padding:"10px 30px"}}>
						
					<form className="row" onSubmit={this.getTruckConsignments.bind(this)}>
						<div className="form-group col-xl-6 col-lg-6">
							<label>Select Type</label>
							<Select 
								className="border-radius-0"
								isMulti={false}
								id="selectedTruckConsignmentsFilter"
								style={{borderRadius:"0px"}} 
								options={[{label: "Invoice No", value: "invoice_no"},{label: "Consignment No", value: "consignment_code"}]}
								required 
							/>
						</div>
						<div className={"col-xl-6 col-lg-6 form-group"}>
						<label className="reason">Invoice No / Consignment No</label>
						<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
						</div>
						<div className="form-group col-xl-12 col-lg-12" style={{}}>
						<button type="submit" style={{}} className="btn btn-success">Submit</button>
						</div>
					


						
					</form>
					</div>
				</div>
			</div>:""}
			{/* Govind */}
			{this.state.sliderRouteTranslate != '' ?
				<div className={`slide-r ${this.state.sliderRouteTranslate}`} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							{(this.state.consignment_code)}
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						{/* <h3>Map comes here</h3> */}
						<div id="map" className="" style={{width:'100%',height:"80vh"}}></div>
						<div className="col-xl-12 col-lg-12 n-p-0">
							<div className="crm-numbers pb-0">
								<h3 className="subH">Consignment Information</h3>
								<div className="col-xl-12 col-lg-12 row">
									<div className="col route-block">
										<label className="sidebar-label">Service Provider</label>
										<div>{this.state.serviceProvider}</div>
									</div>
									<div className="col route-block">
										<label className="sidebar-label">Start Time</label>
										<div>
											{this.state.startDate}
										</div>
									</div>
									<div className="col route-block">
										<label className="sidebar-label">End Time</label>
										<div>
										{this.state.endDate}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			:<></>
		}
		{/* Govind */}

					
					<div className="col-xl-7 col-lg-6">
						<div className=" card" >
							<div className="row card-body"style={{padding:"10px 30px"}}>
							<form method="POST" className="row theme-form col-xl-12 col-lg-12">
								<div className="form-group col-xl-4 col-lg-4">
									<label>Select Consigner </label>
									<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{borderRadius:"0px"}}
									// options={this.selectConsignerOptionsItems()
										options={this.state.consigners} required />
								</div>
								<div className="form-group col-xl-4 col-lg-4">
									<label>From Date</label>
									{/* <input type="text" placeholder="From Date" id="from_date" onChange={this.handlerStartDateTime.bind(this)} value={this.state.from_date} className="datepicker form-control" /> */}
									<input type="text" placeholder="From Date" autoComplete="off" id="from_date" value={this.state.from_date}  className="datetimepicker_date form-control" required />
									
								</div>
								<div className="form-group col-xl-4 col-lg-4">
									<label>To Date</label>
									<input type="text" autoComplete="Off" placeholder="To Date" id="to_date" value={this.state.to_date}  className="datetimepicker_date form-control" required />
									{/* <input type="text" placeholder="To Date" id="to_date" onChange={this.handlerEndDateTime.bind(this)} value={this.state.to_date} className="datepicker form-control" /> */}
								</div>

								<div className="form-group col-xl-12 col-lg-12">
									<button className="btn btn-success" type="button" onClick={this.submitForm.bind(this)}>Submit</button>
								</div>
							</form>
							</div>
						</div>
					</div>
				</div>
				
				{(this.props.match.path !="/billingreportcourierconsignments" )?
				<div className="row">
				<div className="col-xl-12 col-lg-12">

					<CourierCounter context={this} 
						records={this.state.rowData} 
						intransitcount={this.state.intransitcount}
						outdelivercount={this.state.outdelivercount}
						delivercount={this.state.delivercount}
						datanacount={this.state.datanacount}
						prioritydatacount={this.state.prioritydatacount}
						total={this.state.totalcount}
						on_time_data={this.state.on_time_data}
						delayed_data={this.state.delayed_data}
						counttype={this.state.counttype}
						critical_counter_data = {this.state.critical_counter_data.length}
						confirmDelivery = {this.state.confirmDelivery.length}
						cancelled_data = {this.state.cancelled_data}
						trip_closed_by_pod = {this.state.trip_closed_by_pod.length}
						pod_received_data = {this.state.pod_received_data.length}
						path={this.props.match.path}
						on_time_data_count={this.state.on_time_data_count}
						delayed_data_count={this.state.delayed_data_count}
						forceclosecount = {this.state.forceclosecount}
						force_close_count = {this.state.force_close_count}
					/> 
				</div>
			</div>:""}
				
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			
								{(this.state.counttype == "active" && localStorage.getItem('user_type') != "COURIER_CONSIGNEE")?
								<div>
									{/* <div className="float-right" style={{marginLeft:"7px"}}>
										<button
											type="button" 
											title="Write Comment"
											onClick={this.onBulkMarkDeliverBtn.bind(this)}
											className={"btn se-ico btn-info ml-5px "+(this.state.hideTransporterBtns)}
											>
											Bulk Mark Delivered
										</button>
									</div> */}
									<div className="float-right" style={{marginLeft:"7px"}}>
										<button
											title="Bulk Upload"
											onClick={this.onBulkForceCloseBtn.bind(this)}
											className={"btn se-ico btn-success ml-5px "+(this.state.hideTransporterBtns)}
											type="button">
											Bulk Force Close
										</button>
									</div>
								</div>
								:""}
								{(this.props.match.path !="/billingreportcourierconsignments" && localStorage.getItem('user_type') != "COURIER_CONSIGNEE")?
								<div className="float-right">
									<button
										title="Bulk Upload"
										onClick={this.onBulkPriorityBtn.bind(this)}
										className={"btn se-ico btn-danger ml-5px "+(this.state.hideTransporterBtns)}
										type="button">
										Bulk Mark Priority
									</button>
								</div>:""}
										
									{
									((this.props.match.path == "/courierconsignments" || this.props.match.path == "/activecourierconsignments") && localStorage.getItem('user_type') != "COURIER_CONSIGNEE")?
										<div className="float-right">
											<button type="button" style = {{marginRight:"1em"}} className="btn btn-warning" onClick={this.onClickShowForm.bind(this)}>Add Consignments</button>
											<button type="button" style = {{marginRight:"1em"}} className="btn btn-warning" onClick={this.onClickShowForm1.bind(this)}>Bulk Upload</button>
										</div>
										:""
									}
									{
									(this.props.match.path =="/deliveredcourierconsignments" && localStorage.getItem('user_type') != "COURIER_CONSIGNEE")?
									<div className="float-right">
											<button type="button" style = {{marginRight:"1em"}} className="btn btn-warning" onClick={this.onClickShowForm2.bind(this)} >Bulk Add Remarks</button>
										</div>
										:""
									}
									{
									(this.props.match.path =="/deliveredcourierconsignments" && (localStorage.getItem("email") == "admin@volvo.com"|| localStorage.getItem("email") == "gsharma10@VECV.IN" ||localStorage.getItem("email") == "nnandwal@VECV.IN"||localStorage.getItem("email") == "vpal3@VECV.IN") && localStorage.getItem('user_type') != "COURIER_CONSIGNEE")?
									<div className="float-right">
											<button type="button" style = {{marginRight:"1em"}} className="btn btn-danger" onClick={this.onClickShowFormValidateRamrks.bind(this)} >Bulk Validate Remarks</button>
										</div>
										:""
									}
									{
									(this.props.match.path =="/deliveredcourierconsignments" && localStorage.getItem('user_type') != "COURIER_CONSIGNEE" )?
									<div className="float-right">
											<button type="button" style = {{marginRight:"1em"}} className="btn se-ico btn-info ml-5px " onClick={this.onClickShowForm3.bind(this)} >Bulk Mark Delivered</button>
										</div>
										:""
									}
							   </div>
							   
		            		<div className="row  card-body">
								<div className="col-xl-12">
									<span className="layoutbtns float-right">
										<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>
								</div>
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowSelection={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									onRowSelected={this.onRowSelection.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									onCellClicked={this.onCellClicked.bind(this)}
		          		          />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
									<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderForceCloseTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Force Closure
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
					<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"302px"}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>
								
                                <input type="text" placeholder="Reached Dealer " id="reached_dealer_time" className="datetimepicker_mask form-control"  />
							
							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>
								
                                <input type="text" placeholder="Left Dealer " id="left_dealer_time" className="datetimepicker_mask form-control" />
							
							</div> */}
							{/* <ForceCloseSideBar context={this} handleInput = {this.handleInput}/> */}
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reason for closure</label>
								<textarea name="reasonforceclose" id="forceClosureResonID" rows="5" className="form-control col-xl-12 col-lg-12" required></textarea>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
                        </div>
					</div>
				</div>
				
					<div className={"slide-r "+(this.state.sliderMarkDeliverTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Mark Delivered
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
					<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"302px"}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formMarkDeliverHandler.bind(this)}>
							
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Delivery Time</label>
                                <input type="text" autoComplete="off" class="form-control datetimepicker_date" value={this.state.markDeliverDefault} id="delivery_date_time" required/>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
                        </div>
					</div>
					</div>
				<div className={"slide-r " + (this.state.sliderRemarksTranslate)} style={{overflow:"auto"}} >
				<div className="slide-r-title">
                        <h4>
                           Courier Delayed Remarks
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
					<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"302px"}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formCourierDelayRemarks.bind(this)}>
							
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Remarks</label>
                                <input type="text" autoComplete="off" class="form-control ml-2" 
								// onChange={this.onChangeRemarks} 
								// value={this.state.remarksInput} 
								id="courier_delayed_remarks" required/>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
                        </div>
				</div>
				<div className={"slide-r "+(this.state.bulkPrioritySlide)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Priority</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkPriorityFormHandler.bind(this)}>

							<div className="form-group mt-20p">
								<label className="">Upload File <span style={{color:'red'}}>*</span></label> 
								<input type="file" name="uploadFile" id="bulkUploadPID" onChange={this.changePriorityFileHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_mark_deliver_sample.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
							
							</div>		
						</div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.bulkForceCloseSlide)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Force Close</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkForceFormHandler.bind(this)}>

							<div className="form-group mt-20p">
								<label className="">Upload File <span style={{color:'red'}}>*</span></label> 
								<input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							<div className="form-group">
								<p style={{color:"#ff0000"}}>* Note : Date format should be in dd-mm-yyyy</p>
						
							</div>
							<div className="form-group">
								<p style={{color:"#ff0000"}}>* Note : All fields related to Force close are mandatory and cannot be empty</p>
								{/* <p style={{color:"green"}}>* Please use the below fields as headers while uploading</p>
								<p style={{color:"cornflowerblue"}}> -> consignment_code</p>
								<p style={{color:"cornflowerblue"}}> -> reason</p> */}
						
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_force_close_sample.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
							
							</div>		
						</div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.bulkMarkDeliverSlide)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Mark Delivered</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkMarkDeliverHandler.bind(this)}>

							<div className="form-group mt-20p">
								<label className="">Select Delivery Time</label> 
								<input type="text" autoComplete="off" class="form-control datetimepicker_date" id="bulk_delivery_date_time" required/>
							</div>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="bulkUploadMDID" onChange={this.changeMDHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							<div className="form-group">
								<p style={{color:"#ff0000"}}>*Note: </p>
								<p><b style={{color:"#ff0000"}}> * </b>All fields related to Mark Delivered are mandatory and cannot be empty</p>
								<p><b style={{color:"#ff0000"}}> * </b>Delivery Date Must be Greater than Invoice Date.</p>
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_mark_deliver_sample.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
							
							</div>		
						</div>
					</div>
				</div>
					
				<div className={"slide-r " + (this.state.sliderCreateConsignment)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Add Consignment
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveConsignmentData.bind(this)}>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Truck :</label>

									<Select
										placeholder={"Select Truck"}
										closeMenuOnSelect={true}
										onChange={this.onChangeTruckItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="truck_no"
										value={this.state.truck_no}
										options={this.state.trucklist} />
								</div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Consignment Code</label>
									<input
										type="text"
										className="form-control ml-3"
										onChange={this.changeHandler.bind(this)}
										value={this.state.grno_consignment_code}
										autoComplete="off"
										name="grno_consignment_code"
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Courier Service Provider</label>

									<Select
										placeholder={"Select Service"}
										closeMenuOnSelect={true}
										onChange={(e) => { this.setState({ selectedService: e }) }}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px"}}
										name="courier_status"
										value={this.state.selectedService}
										options={[{ label: "Gati", value: "gati" }, { "label": "Bluedart", value: "bluedart" },
										{ "label": "Delhivery", value: "delhivery" }, { "label": "Safexpress", value: "safexpress" }
										, { "label": "Om Logistics Limited", value: "om logistics limited" }]} 
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Plant Code</label>

									<Select
										placeholder={"Select Plant Code"}
										closeMenuOnSelect={true}
										onChange={(e) => { this.setState({ selectedPlantCode: e }) }}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="plant_code"
										value={this.state.selectedPlantCode}
										options={this.state.plantcouriercodes} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Dealer Code</label>

									<Select
										placeholder={"Select Dealer Code"}
										closeMenuOnSelect={true}
										onChange={(e) => { this.setState({ selectedDealerCode: e }) }}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="dealer_code"
										value={this.state.selectedDealerCode}
										options={this.state.dealercouriercode} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Dealer City</label>

									<Select
										placeholder={"Select Dealer City"}
										closeMenuOnSelect={true}
										onChange={(e) => { this.setState({ selectedDealerCity: e }) }}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="dealer_city"
										value={this.state.selectedDealerCity}
										options={this.state.dealercouriercity} />
								</div>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">MGPA No</label>
									<input
										type="text"
										className="form-control"
										onChange={this.changeHandler.bind(this)}
										value={this.state.mgpa_number}
										autoComplete="off"
										name="mgpa_number"
									/>
								</div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Transit Time</label>
									<input
										type="text"
										className="form-control ml-3"
										onChange={this.changeHandler.bind(this)}
										value={this.state.transit_time}
										autoComplete="off"
										name="transit_time"
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Mode</label>
									<input
										type="text"
										className="form-control ml-3"
										onChange={this.changeHandler.bind(this)}
										value={this.state.courier_vehicle_mode}
										autoComplete="off"
										name="courier_vehicle_mode"
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Invoice Date</label>
									<input
										type="text"
										className="form-control datetimepicker_mask ml-3"
										id="mgpa_datetime"
										name="mgpa_datetime"
										autoComplete="off"
									/>
								</div>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">GateOut Time</label>
									<input
										type="text"
										className="form-control datetimepicker_mask"
										id="gate_out_time"
										name="gate_out_time"
										autoComplete="off"
									/>
								</div> */}
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Plant :</label>

									<Select
										placeholder={"Select Plant"}
										closeMenuOnSelect={true}
										onChange={this.onChangePlantItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="plant_code"
										value={this.state.plant_code}
										options={this.state.plantlist} />
								</div>
								</div> */}

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
				<div className="slide-r-title">
						<h4>
							Bulk Upload
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>

                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Upload File <span style={{color:'red'}}> *</span></label>
                                <input
                                    type="file"
                                    name="uploadfile"
									id = "uploadfile"
                                    className="form-control"
                                    onChange={this.changeFileHandlerFc.bind(this)} />
                            </div>

                            <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group" style={{color:"red",fontWeight:"bold"}}>
                                Date format should be in dd-mm-yyyy
                            </div>
							<div className="col-xl-12 col-lg-12 form-group" style={{color:"red",fontWeight:"bold"}}>
                                Note : Service Providers Gati / Bluedart / Safexpress / Delhivery are only allowed
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/sample_bulk_consignment_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                        </form>
                        
					</div>
				</div>
				</div>
				<div className={"slide-r " + (this.state.sliderBulkMarkDeliverd)} style={{ overflow: "auto" }}>
				<div className="slide-r-title">
						<h4>
							Bulk Mark Delivered
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkMarkDeliverd.bind(this)} >
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Upload File <span style={{color:'red'}}>*</span></label>
                                <input
                                    type="file"
                                    name="uploadfile"
									id = "uploadfile2"
                                    className="form-control"
									onChange={this.changeFileHandlerFcforMarkDeliverd.bind(this)}
                                    />
									 
                            </div>

                            <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                           
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/mark_deliverd - Sheet1.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                        </form>
                        
					</div>
				</div>
				</div>

				<div className={"slide-r " + (this.state.sliderBulkRemarks)} style={{ overflow: "auto" }}>
				<div className="slide-r-title">
						<h4>
							Bulk Add Remarks
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkAddRemaks.bind(this)} >
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Upload File <span style={{color:'red'}}>*</span></label>
                                <input
                                    type="file"
                                    name="uploadfile"
									id = "uploadfile1"
                                    className="form-control"
                                    onChange={this.changeFileHandlerFc1.bind(this)} />
                            </div>

                            <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                           
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/sample_templet_for_remarks.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                        </form>
                        
					</div>
				</div>
				</div>

				<div className={"slide-r " + (this.state.sliderBlukValidateRemarks)} style={{ overflow: "auto" }}>
				<div className="slide-r-title">
						<h4>
							Bulk Validate Remarks
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkValidateRamarks.bind(this)} >
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Upload File <span style={{color:'red'}}>*</span></label>
                                <input
                                    type="file"
                                    name="uploadfile"
									id = "uploadfile1"
                                    className="form-control"
                                    onChange={this.changeFileHandlerValidateRemarks.bind(this)} />
                            </div>

                            <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                           
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/bulk_validate_remarks.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                        </form>
                        
					</div>
				</div>
				</div>

				<Modal open={delivermodal} onClose={this.onCloseDeliverModal} styles={modalStyles}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
					<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formSetDeliveredDate.bind(this)}>
							
							<div className="row form-group col-xl-12 col-lg-12">
								<label className="col-xl-4 col-lg-4">Reason For Delay/Current Status</label>
								<Select 
								className="border-radius-0 col-xl-6 col-lg-6"
								isMulti={false}
								id="selectedFilter"
								onChange={this.setSelectedReason.bind(this)}
								style={{borderRadius:"0px"}} 
								options={this.selectReason()} required />
							</div>

							<div className={"col-xl-12 col-lg-12 row form-group"} style={{display:this.state.delDate}}>
								<label className="col-xl-4 col-lg-4">Delivery Date</label>
								<Datetime 
                                    value={this.state.deliverdate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'Delivery Date', name: 'deliverdate', autoComplete:'off' }} 
                                    dateFormat="YYYY-MM-DD"  
                                    name="deliverdate"
                                    className="col-xl-6 col-lg-6" 
                                    onChange={this.handlerDeliverDateTime.bind(this)} 
                                />
							</div>
							<div className={"form-group col-xl-12 col-lg-12 row "+(this.state.displayOtherField)}>
								<label className="col-xl-4 col-lg-4" style={{float: "left"}}>Other Reason</label>
								<textarea name="reason" onChange={this.changeReasonHandler.bind(this)} rows="5" className="form-control col-xl-6 col-lg-6" ></textarea>
							</div>	
							<div className="col-xl-12 col-lg-12 form-group" style={{textAlign:"center"}}>
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
					</div>
				</Modal>
            </div>
              	
		);
	}
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}


function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	// console.log(date.length);
	// console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('.datetimepicker_mask1').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
};

function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
		maxDate:'0'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function differenceInDays(startDate,endDate){
	// console.log(startDate,endDate);
	startDate = moment.parseZone(startDate);
	endDate = moment.parseZone(endDate);
	// console.log(startDate);
	// console.log(endDate);
	var res = (startDate - endDate)/1000;
	return (res)/86400;
}

function deliveryInTransit(startDate,endDate){
	// console.log(startDate,endDate);
	endDate = endDate.split("T")[0];
	if(typeof(startDate) == "string")
	{
		if(startDate.indexOf(":") >= 0)
		{
			startDate = startDate.split(" ")[0];
		}
		if(startDate.indexOf(":")>=0 && startDate.indexOf("T") >= 0)
		{
			startDate = startDate.split("T")[0];
		}

		// console.log(startDate);
		// console.log(startDate.split("-")[1]+"/"+startDate.split("-")[2]+"/"+startDate.split("-")[0]);
		// console.log(endDate.split("-")[1]+"/"+endDate.split("-")[2]+"/"+endDate.split("-")[0]);
		if(startDate.indexOf("/") < 0){
			startDate = new Date(startDate.split("-")[1]+"/"+startDate.split("-")[2]+"/"+startDate.split("-")[0]);
		}
		else{
			startDate = new Date(startDate.split("/")[1]+"/"+startDate.split("/")[2]+"/"+startDate.split("/")[0]);
		}
	}
	else{
		var dd = String(startDate.getDate()).padStart(2, '0');
		var mm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = startDate.getFullYear();
		startDate = mm + '/' + dd + '/' + yyyy;
		startDate = new Date(startDate);
	}
	
	endDate = new Date(endDate.split("-")[1]+"/"+endDate.split("-")[2]+"/"+endDate.split("-")[0]);
	// console.log(startDate,endDate);
	var difference = startDate.getTime() - endDate.getTime();
	// console.log(difference);
	// var res = Math.abs((startDate - endDate)/1000);
	return Math.abs(Math.round((difference)/(1000*3600*24)));

}

function InTransitDelay(startDate,endDate){
	endDate = endDate.split("T")[0];
	var dd = String(startDate.getDate()).padStart(2, '0');
	var mm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
	var yyyy = startDate.getFullYear();
	startDate = mm + '/' + dd + '/' + yyyy;
	startDate = new Date(startDate);
	endDate = new Date(endDate.split("-")[1]+"/"+endDate.split("-")[2]+"/"+endDate.split("-")[0]);
	console.log(startDate,endDate);
	var difference = startDate.getTime() - endDate.getTime();
	console.log(difference);
	// var res = Math.abs((startDate - endDate)/1000);
	return Math.abs(Math.round((difference)/(1000*3600*24)));
}

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 
function checkIfArrayIsUnique(myArray) {
    return myArray.length === new Set(myArray).size;
}